import { motion, AnimatePresence } from "framer-motion"
import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"

import styled from "styled-components"

import Logo from "../Photos/Logo.png"

import {NavigateNext} from '@styled-icons/material/NavigateNext'
import {NavigateBefore} from '@styled-icons/material/NavigateBefore'

import Slideshow1 from '../Photos/Slideshow1.jpg'
import Slideshow2 from '../Photos/Slideshow2.jpg'
import Slideshow3 from '../Photos/Slideshow3.jpg'
import Slideshow4 from '../Photos/Slideshow4.jpg'
import Slideshow5 from '../Photos/Slideshow5.jpg'

const Slideshow = () =>{

    const [currentImage, setCurrentImage] = useState(0)
    const [initialX, setInitialX] = useState(900)

    const slidesArray = [Slideshow1, Slideshow2, Slideshow3, Slideshow4, Slideshow5]


    const handlePreviousButtonClick = () =>{
        setInitialX(-900)

        if(currentImage === 0){
            setCurrentImage(slidesArray.length - 1)
        }else{
            setCurrentImage(currentImage - 1)
        }
    }

    const handleNextButtonClick = () =>{
        setInitialX(900)
        setCurrentImage(currentImage + 1)

        if(currentImage === slidesArray.length - 1){
            setCurrentImage(0)
        }else{
            setCurrentImage(currentImage + 1)
        }
    }

    useEffect(() =>{
        const timer = setInterval(() =>{
            setInitialX(500)
            if(currentImage == slidesArray.length-1){
                setCurrentImage(0)
            }else{
                setCurrentImage(currentImage + 1)
            }
        }, 4000)

        return () =>{
            clearInterval(timer)
        }
    })

    return(
        <AnimatePresence>
            <SlideshowContainer>
                {/* Logo */}
                <LogoContainer>
                    <ImageContainer>
                        <LogoImage src={Logo} alt="Logo"></LogoImage>
                    </ImageContainer>
                    <HeaderTextContainer>
                        <HeaderText>Wildcat Porta-Potti</HeaderText>
                        <HeaderSubText>Blanchester, OH | (513)-431-7945 | Wildcatportapottistore@gmail.com</HeaderSubText>
                    </HeaderTextContainer>
                </LogoContainer>

                {/* The Actual Slideshow (img) */}
                <SlideshowDiv initial={{x: initialX}} animate={{x: 0}} key={slidesArray[currentImage]} alt={slidesArray[currentImage]} src={slidesArray[currentImage]} />

            </SlideshowContainer>
             <SlideButtonsContainer>
                <PrevButtonContainer>
                    <PreviousButton onClick={() => handlePreviousButtonClick()}><PrevIcon /><PrevText>Previous</PrevText></PreviousButton>
                </PrevButtonContainer>
                <NextButtonContainer>
                    <NextButton onClick={() => handleNextButtonClick()}><NextText>Next</NextText><NexIcon /></NextButton>
                </NextButtonContainer>
            </SlideButtonsContainer>
        </AnimatePresence>
    )
}

//Component Container
const SlideshowContainer = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 20px;
    margin-top: 5%;
    box-shadow: 15px 15px 15px rgb(0 0 0 / 0.2);
    @media(max-width: 1300px){
        margin-top: 10%;
    };
`

//Header
const LogoContainer = styled.div`
    z-index: 10;
    margin-top: 50px;
    display: flex;
    position: absolute;
    width: 100%;
    padding: 50px;
    @media(max-width: 1300px){
        margin-top: 30%;
        padding: 20px
    };
`

const ImageContainer = styled.div`
    width: 25%;
`

const LogoImage = styled.img`
  width: 200px;
  display: block;
  margin-left: 10%;
  border-radius: 200px;
  @media(max-width: 1300px){
    width: 75px;
    height: 75px;
    margin-left: 0;
  };
  box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
`

//Header Text
const HeaderTextContainer = styled.div`
    width: 75%;
`

const HeaderText = styled.h1`
  color: white;
  font-size: 94px;
  margin: auto;
  text-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
  @media(max-width: 1300px){
    font-size: 32px;
  };
`

const HeaderSubText = styled.text`
    color: white;
    font-size: 24px;
    margin: auto;
    text-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
    @media(max-width: 1300px){
        font-size: 12px;
    };
`

//Image and it's container

const SlideshowDiv = styled(motion.img)`
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    opacity: 0.6;
    z-index: -10;
`

//Buttons
const SlideButtonsContainer = styled.div`
    height: 10%;
    flex-direction: row;
    display: flex;
`

const PrevButtonContainer = styled.div`
    width: 50%;
    text-align: center;
    margin-top: 20px;
`

const NextButtonContainer = styled.div`
    width: 50%;
    text-align: center;
    margin-top: 20px;
`

const NextButton = styled(Button)`
    margin: auto;
    width: 120px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
    border: 3px solid white;
`

const NexIcon = styled(NavigateNext)`
    width:  40px;
    height: 40px;
`

const NextText = styled.text`
    font-size: 18px;
    margin: auto;
    margin-right: -5px;
`

const PreviousButton = styled(Button)`
    margin: auto;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
    border: 3px solid white;
`

const PrevIcon = styled(NavigateBefore)`
    width:  40px;
    height: 40px;
`

const PrevText = styled.text`
    font-size: 18px;
    margin: auto;
    margin-left: -5px;
`

export default Slideshow