import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getAuth } from 'firebase/auth';
import { getFunctions } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCln2DfY9APLY7YhNvHgSVrBl_sQ1WpDSU",
  authDomain: "wildcatportapotti.firebaseapp.com",
  projectId: "wildcatportapotti",
  storageBucket: "wildcatportapotti.appspot.com",
  messagingSenderId: "645849714470",
  appId: "1:645849714470:web:9d337f5fafd1c398883be7",
  measurementId: "G-KH1E1T1R91"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const functions = getFunctions(app);
export const db = app.firestore();
export const auth = getAuth(app)
export const analytics = getAnalytics(app)
export const eventLog = logEvent