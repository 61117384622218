import React from "react";
import styled from "styled-components"

import {Link} from 'react-router-dom'

const CancelPage = () =>{
    return(
        <AppContainer>
            <CancelContainer>
                <HeaderTag>Something went wrong - Order did not complete</HeaderTag>
                <ReturnHomeContainer>
                    <ReturnHome to={"/"}>Return Home</ReturnHome>
                </ReturnHomeContainer>
            </CancelContainer>
        </AppContainer>
    )
}

const AppContainer = styled.div`
  height: 100vh;
  overflow: scroll;
  position: relative;
  background-color: #2b6cd4;
  background-image: -webkit-linear-gradient(40deg, #013A6B 50%, #004E95 50%);
  
`

const CancelContainer = styled.div`
    text-align: center;
    margin-top: 200px;
`

const ReturnHomeContainer = styled.div`
    margin-top: 100px;
    font-size: 26px;
`

const ReturnHome = styled(Link)`
    color: white;
    text-decoration: none;
    border: 1px solid white;
    padding: 40px;
    &:hover, &:after{
        color: white;
        text-decoration: none;
    }
`

const HeaderTag = styled.h1`
    color: white;
`

export default CancelPage;