import styled from "styled-components";
import { Phone } from "@styled-icons/boxicons-solid/Phone";
import { Email } from "@styled-icons/material/Email";
import { Facebook } from "@styled-icons/boxicons-logos/Facebook";
import { useState } from "react";


const ContactPage = () =>{

    const [facebookHoverColor, setFacebookHoverColor] = useState("rgba(0,0,0,0.3)")

    const handleFacebookEnterHover = () =>{
        setFacebookHoverColor('rgba(0,0,0,0.1)')
    }

    const handleFacebookLeaveHover = () =>{
        setFacebookHoverColor('rgba(0,0,0,0.3);')
    }

    const handleFollowFacebook = () =>{
        window.location.replace('https://www.facebook.com/profile.php?id=100090662465690')
    }

    //Follow on Facebook
    const FacebookContainer = styled.div`
        width: 275px;
        height: 250px;
        margin: auto;
        background-color: ${facebookHoverColor};
        border: 1px solid white;
        border-radius: 5px;
        text-align: center;
        @media(max-width: 800px){
            width: 100%;
            height: 150px;
            margin: 0px;
            margin-bottom: 10px;
        }
    `

    const FacebookIcon = styled(Facebook)`
        color: white;
        width: 100px;
        height: 100px;
        margin-top: 20px;
        @media(max-width: 800px){
            width: 50px;
            height: 50px;
        }
    `

    return(
        <ContactContainer>
            <ContactUsHeaderContainer>
                <ContactUsHeaderText>Contact Us</ContactUsHeaderText>
            </ContactUsHeaderContainer>
            <ContactInnerContainer>
                <PhoneContainer>
                    <PhoneIcon/>
                    <Header>Call Us</Header>
                    <Textp>Cell: (513)-431-7945</Textp>
                    {/* <Landlinep>Landline: (937)-625-4200</Landlinep> */}
                </PhoneContainer>
                <EmailContainer>
                    <EmailIcon/>
                    <Header>Email Us</Header>
                    <Textp>Wildcatportapottistore@gmail.com</Textp>
                </EmailContainer>
                <FacebookContainer 
                    onMouseEnter={() => handleFacebookEnterHover()}
                    onMouseLeave={() => handleFacebookLeaveHover()}
                    onClick={() => handleFollowFacebook()}
                >
                    <FacebookIcon />
                    <Header>Follow us on</Header>
                    <Textp>Facebook</Textp>
                </FacebookContainer>
            </ContactInnerContainer>
        </ContactContainer>
    )
}

const ContactContainer = styled.div`
    
`

const ContactUsHeaderContainer = styled.div`
    text-align: center;
    margin-bottom: 50px;
`

const ContactUsHeaderText = styled.text`
    color: white;
    font-size: 32px;
`

const ContactInnerContainer = styled.div`
    width: 40%;
    height: 250px;
    margin: auto;
    display: flex;
    @media(max-width: 800px){
        display: inline;
    };
`

//Contact via Phone
const PhoneContainer = styled.div`
    width: 275px;
    height: 250px;
    margin: auto;
    background-color: rgba(0,0,0,0.3);
    border: 1px solid white;
    border-radius: 5px;
    text-align: center;
    @media(max-width: 800px){
        width: 100%;
        height: 200px;
        padding: 0px;
        margin-bottom: 10px;
        margin-top: 130px;
    }
`
const PhoneIcon = styled(Phone)`
    color: white;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    @media(max-width: 800px){
        width: 50px;
        height: 50px;
    }
`

//Contact via Email
const EmailContainer = styled.div`
    width: 275px;
    height: 250px;
    margin: auto;
    background-color: rgba(0,0,0,0.3);
    border: 1px solid white;
    border-radius: 5px;
    text-align: center;
    @media(max-width: 800px){
        width: 100%;
        height: 150px;
        margin: 0px;
        margin-bottom: 10px;
    }
`

const EmailIcon = styled(Email)`
    color: white;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    @media(max-width: 800px){
        width: 50px;
        height: 50px;
    }
`

const Header = styled.h2`
    color: white;
`

const Textp = styled.p`
    color: white
`

const Landlinep = styled.p`
    color: white
`

export default ContactPage