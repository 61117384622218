import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Home from './Components/Home';
import SuccessPage from './Components/SuccessPage'
import CancelPage from './Components/CancelPage';
import AdminPage from './Components/AdminPage';

function App(){

    return(
    <Router>
        <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/success" element={<SuccessPage />} />
            <Route exact path="/cancel" element={<CancelPage />} />
            <Route exact path="/admin" element={<AdminPage />} />
        </Routes>
    </Router>    
    )
}

export default App;