import styled from "styled-components";
import { useContext,useState } from "react";
import 'react-dropdown/style.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { db } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

import CurrentCart from "../Cart&Pricing/CurrentCart";
import {CartContext} from "../../CartContext";

const OrderModalOtherInfo = () =>{

    const [startDate, setStartDate] = useState(new Date())
    const [returnDate, setReturnDate] = useState(new Date())

    const cart = useContext(CartContext)

    const productCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);
    const productsArray = cart.items;

    const [name, setName] = useState("");
    const [number, setNumber] = useState();
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("Ohio");
    const [zip, setZip] = useState();
    const [affiliate, setAffiliate] = useState("Null");

    let lineItems = [];

    const checkInput = (input) =>{
        if(input.length === 0){
            alert("Please fill out each field")
        }
    }

    const checkCart = (item) =>{
        if(item.length <= 0){
            alert("Your cart is empty")
        }
    }

    const handleAddAffiliate = () =>{
        if(affiliate !== null){
            const newTotal = 0;
            const currentAffiliate = db
            .collection("affiliates")
            .where('code', '==', affiliate)
            .get()
            .then((querySnapshot) =>{
                querySnapshot.docs.forEach(doc => {
                    doc.ref.update({
                        month: doc.data().month+=1,
                        total: doc.data().total+=1
                    })
                })
            })
        }
    }

    const sendNotificationText = ({name, phone, items, needBy, returnBy}) =>{
        const functions = getFunctions();
        const sendSMS = httpsCallable(functions, 'sendSMS');

        sendSMS({name: name, phone: phone, items: items, needBy: needBy, returnBy: returnBy, address: address})
    }

    const checkout = async () => {

        // When the customer clicks on the button, redirect them to Checkout.

        //Find the current date
        const currentDay = new Date().getDate();
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        const currentDate = currentMonth + " " + currentDay + ", " + currentYear;

        //Get the date that the units are needed
        const needDay = startDate.getDate();
        const needMonth = startDate.getMonth() + 1;
        const needYear = startDate.getFullYear();
        const needOn = needMonth + "-" + needDay + "-" + needYear;

        //Get the date that the units need returned
        const returnDay = returnDate.getDate();
        const returnMonth = returnDate.getMonth() + 1;
        const returnYear =  returnDate.getFullYear();
        const returnOn = returnMonth + "-" + returnDay + "-" + returnYear;

        // //New order object, this gets passed to Firebase
        const newOrder = {
            name: name,
            number: number,
            address: address,
            city: city,
            state: state,
            zip: zip,
            cart: productsArray,
            orderdate: needOn,
            return: returnOn,
        }

        checkInput(name)
        checkInput(number)
        checkInput(address)
        checkInput(city)
        checkInput(state)
        checkInput(zip)
        handleAddAffiliate()

        //Add to Firebase
        await db.collection("orders").add(newOrder)
        
        const items = cart.items;
        items.forEach((item) => {
            lineItems.push({
                price: item.id,
                quantity: item.quantity
            })
        });

        const functions = getFunctions();
        const stripeCheckout = httpsCallable(functions, 'createStripeCheckout');

        await stripeCheckout({items: lineItems}).then((response) =>{
            window.location.replace(response.data)
        })

        ////////////Twilio///////////////
        try{
            sendNotificationText({name: name, phone: number, items: productsArray, needBy: needOn, returnBy: returnOn})
        }catch(error){
            console.log('Error Sending from Twilio: ' + error.message)
        }
    };

    //Handlers for State Changes
    const handleStartDate = (date) =>{setStartDate(date)}
    const handleReturnDate = (date) =>{setReturnDate(date)}
    const handleSetName = (name) =>{setName(name)}
    const handleSetNumber = (number) =>{setNumber(number)}
    const handleSetAddress = (address) =>{setAddress(address)}
    const handleSetCity = (city) =>{setCity(city)}
    const handleSetState = (state) =>{setState(state)}
    const handleSetZip = (zip) =>{setZip(zip)}
    const handleSetAffiliate = (affiliate) =>{setAffiliate(affiliate)}

    return(
        <>
            <DateSelection>
                <StartDateContainer>
                    <StartLabel>Need By Date:</StartLabel>
                    <StartDatePicker selected={startDate} onChange={(date) => handleStartDate(date)} />
                </StartDateContainer>
                <ReturnDateContainer>
                    <ReturnLabel>Return Date:</ReturnLabel>
                    <ReturnDatePicker selected={returnDate} onChange={(date) => handleReturnDate(date)} />
                </ReturnDateContainer>
            </DateSelection>

            <UserInfoContainer>
                <PersonalInfoContainer>
                    <NameContainer>
                        <Name type="text" placeholder="Name" value={name} onChange={(event) => handleSetName(event.target.value)}/>
                    </NameContainer>
                    
                    <PhoneNumberContainer>
                        <PhoneNumber type="number" placeholder="Phone Number" value={number} onChange={(event) => handleSetNumber(event.target.value)}/>
                    </PhoneNumberContainer>
                </PersonalInfoContainer>
                
                    <AddressContainer>
                        <Address type="text" placeholder="Address" value={address} onChange={(event) => handleSetAddress(event.target.value)}/>
                    </AddressContainer>

                <AddressLineTwoContainer>
                    <CityContainer>
                        <City type="text" placeholder="City" value={city} onChange={(event) => handleSetCity(event.target.value)}/>
                    </CityContainer>
                    <StateZipContainer>
                        
                        <StateContainer>
                            <select id="stateDropdownButton" placeholder="State" onChange={(event) => handleSetState(event.target.value)}>
                                <option value="Ohio">Ohio</option>
                                <option value="Kentucky">Kentucky</option>
                            </select>
                        </StateContainer>

                        <ZipContainer>
                            <Zip type="number" placeholder="Zip" value={zip} onChange={(event) => handleSetZip(event.target.value)}/>
                        </ZipContainer>
                    </StateZipContainer>
                </AddressLineTwoContainer>

                <CartLabel>Your Cart:</CartLabel>
                <BottomContainer>
                    <CurrentCartContianer>
                        {productCount > 0 ?
                        <>
                            {cart.items.map((currentProduct, idx) => (
                                <CurrentCart key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CurrentCart>
                            ))}
                            <TotalCost>Total: ${cart.getTotalCost().toFixed(2)}</TotalCost>
                        </>
                        :
                        <>
                            <NoItemsText>Empty</NoItemsText>
                        </>
                        }
                    </CurrentCartContianer>

                    <CheckoutOrderButtonContainer>
                        <CheckoutButton onClick={() => checkout()}>Checkout</CheckoutButton>
                        <CancelOrderButton onClick={() => cart.emptyCart()}>Cancel</CancelOrderButton>
                        <AffiliateInput placeholder="Affiliate" onChange={(event) => {handleSetAffiliate(event.target.value)}}></AffiliateInput>
                    </CheckoutOrderButtonContainer>

                </BottomContainer>
            </UserInfoContainer>
        </>
    )
}

//Date Selector

const DateSelection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 900px){
        display: inline;
    }
`

const StartDateContainer = styled.div`
    margin-right: 50px;
    @media(max-width: 900px){
        margin-left: 0px;
    }
`

const StartLabel = styled.p`
    margin-top: 5px;
    font-size: 20px;
`

const StartDatePicker = styled(DatePicker)`
    background-color: #013A6B;
    color: white;
    border: 1px solid white;
    margin-bottom: 10px;
    font-size: 24px;
    padding: 5px 10px;
    @media(max-width: 900px){
        width: 460px;
    }
    @media(max-width: 500px){
        width: 335px;
    }
`

const ReturnDateContainer = styled.div`
    margin-left: 50px;
    @media(max-width: 900px){
        margin-left: 0px;
    }
`

const ReturnLabel = styled.p`
    margin-top: 5px;
    font-size: 20px;
`

const ReturnDatePicker = styled(DatePicker)`
    background-color: #013A6B;
    color: white;
    border: 1px solid white;
    margin-bottom: 10px;
    font-size: 24px;
    padding: 5px 10px;
    @media(max-width: 900px){
        width: 460px; 
    }
    @media(max-width: 500px){
        width: 335px;
    }
`

const UserInfoContainer = styled.div`
    height: 600px;
    padding: 10px;
    border: 1px solid white;
    margin-top: 10px;
    background-color: #013A6B;
    @media(max-width: 900px){
        height: 800px;
    }
`

/* Name, Number */
const PersonalInfoContainer = styled.div`
    display: flex;
    height: 50px;
    @media(max-width: 900px){
        display: inline;
    }
`

const NameContainer = styled.div`
    border: 1px solid white;
    width: 100%;
`

const Name = styled.input`
    width: 350px;
    height: 50px;
    border: none;
    background: none;
    padding: 15px;
    font-size: 18px;
    color: white;
    &::placeholder{
        color: white;
    }
    padding-top: 10px;
    &:focus{
        outline: none
    }
`

const PhoneNumberContainer = styled.div`
    border: 1px solid white;
    width: 350px;
    margin-left: 40px;
    @media(max-width: 900px){
        margin-left: 0px;
        margin-top: 15px;
        width: 100%;
    }
`

const PhoneNumber = styled.input`
    width: 350px;
    height: 50px;
    border: none;
    background: none;
    padding: 15px;
    font-size: 18px;
    color: white;
    &::placeholder{
        color: white;
    }
    padding-top: 10px;
    &:focus{
        outline: none
    }
`

/* Address  */
const AddressContainer = styled.div`
    border: 1px solid white;
    width: 100%;
    height: 50px;
    margin-top: 20px;
`

/* City, State, Zip */

const AddressLineTwoContainer = styled.div`
    display: flex;
    @media(max-width: 900px){
        display: inline;
    }
`

const Address = styled.input`
    height: 50px;
    width: 100%;
    border: none;
    background: none;
    padding: 15px;
    font-size: 18px;
    color: white;
    &::placeholder{
        color: white;
    }
    padding-top: 10px;
    &:focus{
        outline: none
    }
`

const CityContainer = styled.div`
    border: 1px solid white;
    width: 300px;
    height: 50px;
    margin-top: 20px;
    margin-right: 25px;
    @media(max-width: 900px){
        width: 100%;   
    }
`
const City = styled.input`
    width: 300px;    
    height: 50px;
    border: none;
    background: none;
    padding: 15px;
    font-size: 18px;
    color: white;
    &::placeholder{
        color: white;
    }
    padding-top: 10px;
    &:focus{
        outline: none
    }
`

const StateZipContainer = styled.div`
    display: flex;
    margin: 0 -10px;
`

const StateContainer = styled.div`
    border: 1px solid white;
    width: 250px;
    height: 50px;
    margin-top: 20px;
    margin-right: 25px;
    @media(max-width: 900px){
        margin-right: 15px;
        margin-left: 10px;
        width: 125px;
    }
`

const ZipContainer = styled.div`
    border: 1px solid white;
    width: 150px;
    height: 50px;
    margin-top: 20px;
    @media(max-width: 900px){
        width: 175px;  
    }
`
const Zip = styled.input`
    width: 150px;
    height: 50px;
    border: none;
    background: none;
    padding: 15px;
    font-size: 18px;
    color: white;
    &::placeholder{
        color: white;
    }
    padding-top: 10px;
    &:focus{
        outline: none
    }
`

const BottomContainer = styled.div`
    margin-top: 15px;
    height: 170px;
    display: flex;
    @media(max-width: 900px){
        display: inline;  
    }
`

const CurrentCartContianer = styled.div`
    border: 1px solid white;
    width: 500px;
    height: 310px;
    margin: 10px;
    margin-top: 0px;
    overflow-y: auto;
    text-decoration: none;
    scrollbar-width: none;
    @media(max-width: 900px){
        font-size: 12px;
        width: 300px;
        height: 325px;
    }
`

const TotalCost = styled.h3`
    margin-left: 10px;
    font-weight: bold;
    margin-right: auto;
`

const NoItemsText = styled.text`
    padding: 20px;
    margin-top: 20px;
`

const CartLabel = styled.h3`
    margin: 10px;
`

const CheckoutOrderButtonContainer = styled.div`
    width: 200px;
    @media(max-width: 900px){
        display: flex;
        margin: auto;
        margin-top: 20px;
        width: 300px;
    }
`

const CheckoutButton = styled.button`
    width: 205px;
    height: 65px;
    margin: 10px 0;
    background-color: #004E95;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    margin-top: 0px;
    @media(max-width: 900px){
        width: 105px;
        height: 50px;
        margin: auto;
        margin-right: 10px; 
    }
`
const CancelOrderButton = styled.button`
    width: 205px;
    height: 65px;
    background-color: #004E95;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    margin: 10px 0px;
    @media(max-width: 900px){
        width: 125px;
        height: 50px;
        margin: auto;
        margin-right: 10px; 
    }
`

const AffiliateInput = styled.input`
    width: 205px;
    height: 65px;
    background-color: #004E95;
    border: 1px solid white;
    color: white;
    margin: 10px 0px;
    text-align: center;
    ::placeholder{
        color: white
    };
    &::after{

    }
    @media(max-width: 900px){
        width: 105px;
        height: 50px;
        margin: auto;
    }
`

export default OrderModalOtherInfo;