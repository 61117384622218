import { useEffect, useState } from "react"

import { Dropdown, Modal } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import styled from "styled-components"

import { db } from '../../firebase'

const Products = () =>{
    
    const [products, setProducts] = useState([])
    const [showEditModal, setEditModal] = useState(false)
    const [showAddModal, setAddModal] = useState(false)

    const [id, setId] = useState('')
    const [document, setDocument] = useState()
    
    //Placeholders for when the item modal opens
    const [name, setName] = useState('Name')
    const [price, setPrice] = useState()
    const [quantity, setQuantity] = useState()
    const [activeStatus, setActiveStatus] = useState(true)

    //User Input for New Products
    const [newProdID, setNewProdID] = useState('')
    const [newProdName, setNewProdName] = useState('')
    const [newProdPrice, setNewProdPrice] = useState('')
    const [newProdQuantity, setNewProdQuantity] = useState('')

    useEffect(() =>{
        db.collection("products")
          .onSnapshot((querySnapshot) =>{
              const tempArray = []
              querySnapshot.forEach((doc) =>{
                  tempArray.push({
                    document: doc.id, 
                    id: doc.data().id,
                    name: doc.data().name,
                    price: doc.data().price,
                    quantity: doc.data().quantity,
                    status: doc.data().status
                  })
              })
              setProducts(tempArray)
          })
    }, [activeStatus])

    const handleCloseEdit = () => setEditModal(false);
    const handleCloseAdd = () => setAddModal(false);

    const handleSelectedStatus = () => {
        if(activeStatus === false){
            setActiveStatus(true);
        }else{
            setActiveStatus(false);
        }
        console.log("Status: " + activeStatus)
    }

    const handleDeleteButton = async () =>{
        await db.collection('products').doc(document).delete()
    }

    const handleSaveButton = async () =>{ 
        await db.collection('products').doc(document).update({
            id: id,
            name: name, 
            price: price,
            quantity: quantity,
            status: activeStatus
        }).then(()=>{
            setActiveStatus(true)
            console.log('Updated document: ' + document)
        }).catch((e) =>{
            console.log(e)
        })
    }

    const handleAddButton = () =>{
        setAddModal(true)
    }
    
    const handleAddProduct = async () =>{
        await db.collection('products').add({
            id: newProdID,
            name: newProdName,
            price: newProdPrice,
            quantity: newProdQuantity,
            status: true
        })
    }

    return(
        <>
        
        <EditModal show={showEditModal} onHide={handleCloseEdit}>
            <ModalHeader closeButton closeVariant="white">
                Edit item: {id}
            </ModalHeader>
            <ModalBody>
                <EditDiv>
                    <EditLabel>ID:</EditLabel>
                    <InputEdit placeholder={id} onChange={(event) => setId(event.target.value)}></InputEdit>
                </EditDiv>
                <EditDiv>
                    <EditLabel>Name:</EditLabel>
                    <InputEdit placeholder={name} onChange={(event) => setName(event.target.value)}></InputEdit>
                </EditDiv>
                <EditDiv>
                    <EditLabel>Price:</EditLabel>
                    <InputEdit placeholder={price} type="number" onChange={(event) => setPrice(event.target.value)}></InputEdit>
                </EditDiv>
                <EditDiv>
                    <EditLabel>Quantity:</EditLabel>
                    <InputEdit placeholder={quantity} type="number" onChange={(event) => setQuantity(event.target.value)}></InputEdit>
                </EditDiv>
                
                <StatusContainer>
                    <StatusForm>
                        <StatusFormCheck
                            type="switch"
                            id="custom-switch"
                            label="Active"
                            checked={activeStatus === true}
                            onChange={() => handleSelectedStatus()}
                        />
                    </StatusForm>
                </StatusContainer>

                <ButtonsContainer>
                    <DeleteButton onClick={() => handleDeleteButton()}>Delete Item</DeleteButton>
                    <SubmitButton onClick={() => handleSaveButton()}>Save</SubmitButton>
                </ButtonsContainer>
            </ModalBody>
        </EditModal>

        <AddModal show={showAddModal}>
            <ModalHeader closeButton closeVariant="red" onHide={handleCloseAdd}>
                ADD A NEW PRODUCT
            </ModalHeader>
            <AddModalBody>
                <AddDiv>
                    <EditLabel>Stripe ID</EditLabel>
                    <InputAdd placeholder={newProdID} onChange={(event) => setNewProdID(event.target.value)}></InputAdd>
                </AddDiv>
                <AddDiv>
                    <EditLabel>Name:</EditLabel>
                    <InputAdd placeholder={newProdName} onChange={(event) => setNewProdName(event.target.value)}></InputAdd>
                </AddDiv>
                <AddDiv>
                    <EditLabel>Price:</EditLabel>
                    <InputAdd type="number" placeholder={newProdPrice} onChange={(event) => setNewProdPrice(event.target.value)}></InputAdd>
                </AddDiv>
                <AddDiv>
                    <EditLabel>Quantity:</EditLabel>
                    <InputAdd type="number" placeholder={newProdQuantity} onChange={(event) => setNewProdQuantity(event.target.value)}></InputAdd>
                </AddDiv>
                <ButtonsContainer>
                    <CreateButton onClick={() => handleAddProduct()}>Add Product</CreateButton>
                </ButtonsContainer>
            </AddModalBody>
        </AddModal>

        <ProductsContainer>
                <table>
                    <TableHead>
                        <TableData id="unitTableData"><THead>ID:</THead></TableData>
                        <TableData id="unitTableData"><THead>Name:</THead></TableData>
                        <TableData id="unitTableData"><THead>Price:</THead></TableData>
                        <TableData id="unitTableData"><THead>Quantity:</THead></TableData>
                        <TableData id="unitTableData"><THead>Status:</THead></TableData>
                        <TableData id="unitTableData">
                            <AddProductButton onClick={() => handleAddButton()}>Add a new Product</AddProductButton>
                        </TableData>
                    </TableHead>
                    <tbody>
                        {products.map((product, idx) => (
                        <TableRow id="tableRow">
                            <TableData id="unitTableData" key={1 + idx} >{product.id}</TableData>
                            <TableData id="unitTableData" key={1 + idx} >{product.name}</TableData>
                            <TableData id="unitTableData" key={1 + idx} >{product.price}</TableData>
                            <TableData id="unitTableData" key={1 + idx} >{product.quantity}</TableData>
                            {
                                product.status === true 
                                ? <TableData id="unitTableData" key={1 + idx} >True</TableData>
                                : <TableData id="unitTableData" key={1 + idx} >False</TableData>
                            }
                            
                            <TableData id="unitTableData" key={1 + idx} >
                                <EditProductButton onClick={() =>{
                                    setDocument(product.document)
                                    setId(product.id)
                                    setName(product.name)
                                    setPrice(product.price)
                                    setQuantity(product.quantity)
                                    setActiveStatus(product.status)
                                    setEditModal(true)
                                }}>EDIT</EditProductButton>
                            </TableData>
                        </TableRow>
                        ))}
                    </tbody>
                </table>
            </ProductsContainer>
        </>
    )
}

export default Products

//Add Product Modal
const AddModal = styled(Modal)`
    
`

const AddModalBody = styled(Modal.Body)`
    background-color: #004E95;
    color: white;
    border: 1px solid white;
    width: 700px;
`

const AddDiv = styled.tr`
    border-bottom: 1px solid white;
    margin-bottom: 15px;
    display: block;
    overflow: hidden;
`

const InputAdd = styled.input`
    float: right;
    margin-bottom: 15px;
    width: 80%; 
    background: none;
    border: none;
    border-bottom: 1px solid white;
    padding: 5px;
    color: white;
    &::placeholder{
        color: white;
    };
    &:focus{
        outline: none
    }
`

const CreateButton = styled.button`
    height: 40px;
    width: 240px;
    margin-right: 20%
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B
`

//General Site styles

const ProductsContainer = styled.div`
border: 1px solid white;
height: 500px;
width: 80%;
margin: 25px auto;
overflow: scroll;
overflow-x: hidden;
&::-webkit-scrollbar {
    width: 25px;
    background: transparent;
    border: 1px solid white;
};
&::-webkit-scrollbar-thumb {
    background: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
};
`

const TableHead = styled.thead`

`

const THead = styled.th`
    
`   

const TableRow = styled.tr`
    
`

const TableData = styled.td`
    
`

const EditProductButton = styled.button`
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B;
    width: 100%;
    height: 45px;
    text-align: center;
`

const AddProductButton = styled.button`
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B;
    width: 100%;
    height: 45px;
    text-align: center;
    font-weight: bold;
`

//MODAL 

const EditModal = styled(Modal)`
    
`

const ModalHeader = styled(Modal.Header)`
    font-size: 20px;
    background-color: #004E95;
    color: white;
    border: 1px solid white;
    border-bottom: none;
    width: 700px;
`

const ModalBody = styled(Modal.Body)`
    background-color: #004E95;
    color: white;
    border: 1px solid white;
    width: 700px;
`

//Modal Data

const EditDiv = styled.tr`
    border-bottom: 1px solid white;
    margin-bottom: 15px;
    display: block;
    overflow: hidden;
`

const EditLabel = styled.h5`
    margin: 0px;
    display: inline;
    float: left;
`

const StatusLabel = styled.h5`
    margin-bottom: 20px;
    display: inline;
    float: left;
`

//Status Selection
const StatusSelect = styled.select`
    margin-left: 10%;
    width: 200px;
    height: 40px;
    background: none;
    border: none;
    color: white;
`

const StatusOption = styled.option`
    color: white;
    background-color: #013A6B
`

const InputEdit = styled.input`
    float: right;
    margin-bottom: 15px;
    width: 80%; 
    background: none;
    border: none;
    border-bottom: 1px solid white;
    padding: 5px;
    color: white;
    &::placeholder{
        color: white;
    };
    &:focus{
        outline: none
    }
`

const StatusEdit = styled(Dropdown)`

`

const StatusContainer = styled.div`

`

const StatusForm = styled(Form)`
    font-size: 24px;
    
`
const StatusFormCheck = styled(Form.Check)`
    font-size: 24px;
`

const ButtonsContainer = styled.div`
    text-align: center;
    margin-top: 25px;
`

const DeleteButton = styled.button`
    height: 40px;
    width: 120px;
    margin-right: 20%
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B
`

const SubmitButton = styled.button`
    height: 40px;    
    width: 80px;
    margin-left: 20%;
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B
`