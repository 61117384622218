import styled from 'styled-components'

const MissionStatement = () =>{
    return (
        <MissionStatementContainer>
            <MissionStatementInnerContainer>
                <MissionStatementText>
                    At Wildcat Porta-Potti, a family owned and operated business since February 2023, 
                    our rapid growth and expansion underscore our dedication to exceptional customer service. 
                    We prioritize customer convenience and ensure a seamless rental experience where worry is eliminated. We are committed to competitive  
                    pricing and swift response times and we offer a comprehensive range of portable toilet solutions including
                    standard units, handicap accessible options, hand wash stations and tow-behind toilets.
                </MissionStatementText>
            </MissionStatementInnerContainer>

            <MissionStatementInnerContainer>
                <MissionStatementText>
                    Our mission is to deliver peace of mind through hassle-free service, reflecting our unwavering commitment to customer satisfaction and convenience. 
                </MissionStatementText>
            </MissionStatementInnerContainer>
        </MissionStatementContainer>
    )
}

const MissionStatementContainer = styled.div`
    text-align: center;
    margin-top: 10%;
    @media(max-width: 1300px){
        margin-top: 150px;
    };
`

const MissionStatementInnerContainer = styled.div`
    width: 50%;
    margin: auto;
    margin-top: 50px;
    @media(max-width: 1300px){
        width: 80%;
        margin-top: 50px;
    };
`

const MissionStatementText = styled.text`
    font-size: 32px;
    font-weight: 0.5;
    color: white;
    @media(max-width: 1300px){
        font-size: 20px;
    };
`

export default MissionStatement