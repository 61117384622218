import { useEffect, useState } from "react"
import { db } from '../../firebase'
import styled from "styled-components"

const OrdersPlaced =  () =>{
    
    const [orders, setOrders] = useState([])

    useEffect(() =>{
        const ordersGrab = db.collection("orders").orderBy('orderdate')
          .onSnapshot((querySnapshot) =>{
              const tempArray = []
              querySnapshot.forEach((doc) =>{
                  tempArray.push({
                      name: doc.data().name,
                      number: doc.data().number,
                      address: doc.data().address,
                      return: doc.data().return,
                      orderdate: doc.data().orderdate,
                      cart: doc.data().cart
                  })
              })
              setOrders(tempArray)
          })
        return () => ordersGrab();
    }, [])

    return(
        <OrdersContainer>
            <table>
                <thead>
                    <td id="unitTableData"><th>Name:</th></td>
                    <td id="unitTableData"><th>Phone Number:</th></td>
                    <td id="unitTableData"><th>Address:</th></td>
                    <td id="unitTableData"><th>Ordered On:</th></td>
                    <td id="unitTableData"><th>Return Date:</th></td>
                    <td id="unitTableData"><th>Items Ordered:</th></td>
                </thead>
                <tbody>
                    {orders.map((order, idx) => (
                    <TableRow id="tableRow">
                        <TableData id="unitTableData" key={idx} >{order.name}</TableData>
                        <TableData id="unitTableData" key={idx} >{order.number}</TableData>
                        <TableData id="unitTableData" key={idx} >{order.address}</TableData>
                        <TableData id="unitTableData" key={idx}>{order.orderdate}</TableData>
                        <TableData id="unitTableData" key={idx} >{order.return}</TableData>
                        <TableData id="unitTableData" key={idx}>{order.cart.map((item, idx) =>
                            <TableRow id="itemRow" key={idx}>{item.name + " (x" + item.quantity + ")"}</TableRow>
                        )}</TableData>
                    </TableRow>
                    ))}
                </tbody>
            </table>
        </OrdersContainer>
    )
}

const TableRow = styled.tr`

`

const TableData = styled.td`

`

const OrdersContainer = styled.div`
    border: 1px solid white;
    height: 500px;
    width: 80%;
    margin: 25px auto;
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 25px;
        background: transparent;
        border: 1px solid white;
    }
    &::-webkit-scrollbar-thumb {
        background: transparent;
        border: 1px solid white;
        border-radius: 5px;
        padding: 5px;
    }
`

export default OrdersPlaced