import { useState } from "react"
import styled from "styled-components"

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "firebase/analytics";

import { Modal } from 'react-bootstrap'
import { useNavigate} from 'react-router-dom';

import Products from './AdminSubPages/Products'
import OrdersPlaced from "./AdminSubPages/OrdersPlaced";
import Affiliates from "./AdminSubPages/Affiliates";

const AdminPage = () =>{

    const navigate = useNavigate()
    const auth = getAuth()

    const [loggedIn, setLoggedIn] = useState(false);
    const [showLoginModal, setLoginModal] = useState(true)

    const [email, setEmail] = useState("EMAIL")
    const [password, setPassword] = useState("PASSWORD")

    const handleLogin = () =>{
        
        signInWithEmailAndPassword(auth, email, password)
        .then(() =>{
            //User is Signed in 
            if(email === 'cookconnor55@gmail.com' || email === 'wildcatportapottistore@gmail.com'){
                //User is authorized, show the page
                setLoggedIn(true)
                setLoginModal(false)
            }else{
                //They are not authorized, send them to the homepage
                Logout()
            }
        }).catch((error) =>{
            alert("Incorrect!")
        })
        //setShowModal(false)
    }

    const Logout = () =>{
        navigate('/')
    }

    return(
    <AppContainer>
        <Modal show={showLoginModal}>
            <Modal.Header>
                <ModalHeaderContainer>
                    <Header>Sign In</Header>
                </ModalHeaderContainer>
            </Modal.Header>
            <Modal.Body>
                <EmailContainer>
                    <EmailInput placeholder={email} onChange={(event) => setEmail(event.target.value)}></EmailInput>
                </EmailContainer>
                <PasswordContainer>
                    <PasswordInput placeholder={password} type="password" onChange={(event) => setPassword(event.target.value)}></PasswordInput>
                </PasswordContainer>
                <ButtonContainer>
                    <SubmitButton onClick={() => handleLogin()}>LOGIN</SubmitButton>
                </ButtonContainer>
            </Modal.Body> 
            <FooterContainer>
                <FooterMessage>Have an issue? Please contact us at (513)-431-7945</FooterMessage>
            </FooterContainer>
        </Modal>
    {loggedIn ? 
        <AdminPageContainer>
            <HeaderContainer>
                <AdminPageHeader>Wildcat Porta-Potti (Admin)</AdminPageHeader>
            </HeaderContainer>
            <LogoutContainer>
                <LogoutButton onClick={() => Logout()}>LOGOUT</LogoutButton>
            </LogoutContainer>
            <BodyContainer>
                <ProductsHeader>Products</ProductsHeader>
                <Products />
                
                <OrdersHeader>Orders</OrdersHeader>
                <OrdersPlaced />

                <AffiliateHeader>Affiliates</AffiliateHeader>
                <Affiliates />

            </BodyContainer>
        </AdminPageContainer>
        : <></>}
    </AppContainer>
    )
}

const AppContainer = styled.div`
    height: 100vh;
    overflow: scroll;
    position: relative;
    background-color: #2b6cd4;
    background-image: -webkit-linear-gradient(40deg, #013A6B 50%, #004E95 50%);
`
// LOGIN MODAL 
const ModalHeaderContainer = styled.div`
    dispay: block;
    text-align: center; 
`

const Header = styled.p`
    font-size: 28px;
    margin: 0px;
`

const EmailContainer = styled.div`
    margin: 10px;
`

const EmailInput = styled.input`
    width: 100%;
    font-size: 26px;
    &::placeholder{
        color: black;
    }
    &:focus{
        outline: none
    }
`

const PasswordContainer = styled.div`
    margin: 10px;
`

const PasswordInput = styled.input`
    width: 100%; 
    font-size: 26px;
    &::placeholder{
        color: black;
    }   
    &:focus{
        outline: none
    }
`

const ButtonContainer = styled.div`
    width: 100%;
    dispay: block;
    text-align: center;
`

const SubmitButton = styled.button`
    width: 100px;
    height: 40px;
`

const FooterContainer = styled.div`
   
`

const FooterMessage = styled.p`
    text-align: center;
`

//ADMIN PAGE
const AdminPageContainer = styled.div`
    
`

const HeaderContainer = styled.div`
    text-align: center;
    margin-top: 50px;
`

const LogoutContainer = styled.div`
    dispay: block;
    text-align: center;
`

const LogoutButton = styled.button`
    width: 100px;
    height: 40px;
    background-color: #004E95;
    border: 1px solid white;
    color: white;
    font-weight: bold;
`

const AdminPageHeader = styled.h1`
    color: white;
    font-size: 42px;
`

const BodyContainer = styled.div`

`

const ProductsHeader = styled.div`
    font-size: 36px;
    color: white;
    margin-left: 25%;
`

const OrdersHeader = styled.div`
    font-size: 36px;
    color: white;
    margin-left: 25%;
`

const AffiliateHeader = styled.div`
    font-size: 36px;
    color: white;
    margin-left: 25%;
`

export default AdminPage