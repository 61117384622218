import React from "react";
import styled from "styled-components"

import {Link} from 'react-router-dom'

import { db } from "../firebase";
import { useEffect } from "react";

const SuccessPage = () =>{

    useEffect(() =>{
        RemoteQuantityFromProducts()
    }, [])

    const RemoteQuantityFromProducts = async () =>{
        const productsRef = db.collection('products');
        const orders = JSON.parse(localStorage.getItem("order") || "[]");
        
        orders.forEach((orderDoc) =>{
            const orderQuantity = orderDoc.quantity
            const orderId = orderDoc.price
            const querySnapshot = productsRef.where('id', '==', orderId).get()
            .then(function(snapshot){
                snapshot.forEach((function(prodDoc){
                    const productId = prodDoc.id
                    const productQuantity = prodDoc.data().quantity
                    const newQuantity = productQuantity - orderQuantity
                    productsRef.doc(productId).update({quantity: newQuantity})
                }))
            })
        })
    }

    return(
        <AppContainer>
            <SuccessContainer>
                <HeaderTag>Your order was received!</HeaderTag>
                <ReturnHomeContainer>
                    <ReturnHome to={"/"}>Return Home</ReturnHome>
                </ReturnHomeContainer>
            </SuccessContainer>
        </AppContainer>
    )
}

const TestButton = styled.button`
    width: 20px;
    height: 20px;
`

const AppContainer = styled.div`
  height: 100vh;
  overflow: scroll;
  position: relative;
  background-color: #2b6cd4;
  background-image: -webkit-linear-gradient(40deg, #013A6B 50%, #004E95 50%);
  
`

const SuccessContainer = styled.div`
    text-align: center;
    margin-top: 200px;
`

const ReturnHomeContainer = styled.div`
    margin-top: 100px;
    font-size: 26px;
`

const ReturnHome = styled(Link)`
    color: white;
    text-decoration: none;
    border: 1px solid white;
    padding: 40px;
    &:hover, &:after{
        color: white;
        text-decoration: none;
    }
`

const HeaderTag = styled.h1`
    color: white;
`

export default SuccessPage;