import styled from 'styled-components'
import { useEffect, useState } from "react"

import { Modal } from 'react-bootstrap'
import { db } from '../../firebase'

const Affiliates = () =>{

    const [affilates, setAffiliates] = useState()
    
    const [document, setDocument] = useState()
    const [newName, setNewName] = useState()
    const [newCode, setNewCode] = useState()
    const [total, setTotal] = useState(0)
    const [monthly, setMonthly] = useState(0)
    const [newAffiliateID, setNewAffiliateID] = useState('')

    const [showEditModal, setEditModal] = useState(false)
    const [showAddModal, setAddModal] = useState(false)

    const handleCloseEdit = () => setEditModal(false);
    const handleCloseAdd = () => setAddModal(false);

    useEffect(() =>{
        const affiliateGrab = db.collection("affiliates")
          .onSnapshot((querySnapshot) =>{
              const tempArray = []
              querySnapshot.forEach((doc) =>{
                  tempArray.push({
                    document: doc.id,
                    name: doc.data().name,
                    code: doc.data().code,
                    total: doc.data().total,
                    thismonth: doc.data().thismonth
                  })
              })
              setAffiliates(tempArray)
          })
        return () => affiliateGrab();
    }, [])

    const handleAddButton = () =>{
        setAddModal(true)
    }

    const handleEditAffiliate = async () =>{
        await db.collection('affiliates').doc(document).update({
            name: newName, 
            code: newCode,
            total: total,
            thismonth: monthly
        }).then(()=>{
            console.log('Updated document: ' + document)
        }).catch((e) =>{
            console.log(e)
        })
    }

    const handleDeleteButton = async () =>{
        await db.collection('affiliates').doc(document).delete()
    }

    const handleAddAffiliate = async () =>{
        console.log("submit")
        await db.collection('affiliates').add({
            name: newName,
            code: newCode,
            total: 0,
            month: 0
        })
    }

    return(
        <AffiliateContainer>
            <AddModal show={showAddModal}>
                <ModalHeader closeButton closeVariant="red" onHide={handleCloseAdd}>
                    ADD A NEW PRODUCT
                </ModalHeader>
                <AddModalBody>
                    <AddDiv>
                        <AddLabel>Name:</AddLabel>
                        <InputAdd onChange={(event) => setNewName(event.target.value)}></InputAdd>
                    </AddDiv>
                    <AddDiv>
                        <AddLabel>Code:</AddLabel>
                        <InputAdd onChange={(event) => setNewCode(event.target.value)}></InputAdd>
                    </AddDiv>
                    <AddDiv>
                        <AddAffiliateButton onClick={() => handleAddAffiliate()}>Submit</AddAffiliateButton>
                    </AddDiv>
                </AddModalBody>
            </AddModal>

            <EditModal show={showEditModal} onHide={handleCloseEdit}>
                <ModalHeader closeButton closeVariant="white">
                    Edit Code For: {newName}
                </ModalHeader>
                <ModalBody>
                    <EditDiv>
                        <EditLabel>Name:</EditLabel>
                        <InputEdit placeholder={newName} onChange={(event) => setNewName(event.target.value)}></InputEdit>
                    </EditDiv>
                    <EditDiv>
                        <EditLabel>Code:</EditLabel>
                        <InputEdit placeholder={newCode} onChange={(event) => setNewCode(event.target.value)}></InputEdit>
                    </EditDiv>
                    <EditDiv>
                        <EditLabel>Total:</EditLabel>
                        <InputEdit placeholder={total} type="number" onChange={(event) => setTotal(event.target.value)}></InputEdit>
                    </EditDiv>
                    <EditDiv>
                        <EditLabel>This Month:</EditLabel>
                        <InputEdit placeholder={monthly} type="number" onChange={(event) => setMonthly(event.target.value)}></InputEdit>
                    </EditDiv>
                    <ButtonsContainer>
                        <DeleteButton onClick={() => handleDeleteButton()}>Delete Item</DeleteButton>
                        <SubmitButton onClick={() => handleAddAffiliate()}>Submit</SubmitButton>
                    </ButtonsContainer>
                </ModalBody>
            </EditModal>

            <table>
                <TableHead>
                    <TableData id="unitTableData"><THead>Name:</THead></TableData>
                    <TableData id="unitTableData"><THead>Code:</THead></TableData>
                    <TableData id="unitTableData"><THead>Total Hits:</THead></TableData>
                    <TableData id="unitTableData"><THead>Hits This Month:</THead></TableData>
                    <TableData id="unitTableData">
                        <AddAffiliateButton onClick={() => handleAddButton()}>Add New Affiliate</AddAffiliateButton>
                    </TableData>
                </TableHead>
                <tbody>
                    {affilates?.map((affiliate, idx) => (
                    <TableRow id="tableRow">
                        <TableData id="unitTableData" key={idx} >{affiliate.name}</TableData>
                        <TableData id="unitTableData" key={idx} >{affiliate.code}</TableData>
                        <TableData id="unitTableData" key={idx} >{affiliate.total}</TableData>
                        <TableData id="unitTableData" key={idx} >{affiliate.thismonth}</TableData>
                        <TableData id="unitTableData" key={idx} >
                            <EditAffiliateModalButton onClick={() =>{
                                setDocument(affiliate.document)
                                setNewAffiliateID(affiliate.id)
                                setNewName(affiliate.name)
                                setNewCode(affiliate.price)
                                setEditModal(true)
                            }}>EDIT</EditAffiliateModalButton>
                        </TableData>
                    </TableRow>
                    ))}
                </tbody>
            </table>
        </AffiliateContainer>
    )
}

//Add Product Modal
const AddModal = styled(Modal)`
    
`

const AddModalBody = styled(Modal.Body)`
    background-color: #004E95;
    color: white;
    border: 1px solid white;
    width: 700px;
`

const AddDiv = styled.tr`
    border-bottom: 1px solid white;
    margin-bottom: 15px;
    display: block;
    overflow: hidden;
`

const InputAdd = styled.input`
    float: right;
    margin-bottom: 15px;
    width: 80%; 
    background: none;
    border: none;
    border-bottom: 1px solid white;
    padding: 5px;
    color: white;
    &::placeholder{
        color: white;
    };
    &:focus{
        outline: none
    }
`

//General Site styles
const AffiliateContainer = styled.div`
border: 1px solid white;
height: 500px;
width: 80%;
margin: 25px auto;
overflow: scroll;
overflow-x: hidden;
&::-webkit-scrollbar {
    width: 25px;
    background: transparent;
    border: 1px solid white;
};
&::-webkit-scrollbar-thumb {
    background: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
};
`

const TableHead = styled.thead``
const THead = styled.th``   
const TableRow = styled.tr``
const TableData = styled.td``

const EditAffiliateModalButton = styled.button`
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B;
    width: 100%;
    height: 45px;
    text-align: center;
`

const DeleteButton = styled.button`
    height: 40px;
    width: 120px;
    margin-right: 20%
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B
`

const AddAffiliateButton = styled.button`
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B;
    width: 100%;
    height: 45px;
    text-align: center;
    font-weight: bold;
`

const EditAffiliateButton = styled.button`
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B;
    width: 100%;
    height: 45px;
    text-align: center;
    font-weight: bold;
`

//MODAL 

const EditModal = styled(Modal)`
    
`

const ModalHeader = styled(Modal.Header)`
    font-size: 20px;
    background-color: #004E95;
    color: white;
    border: 1px solid white;
    border-bottom: none;
    width: 700px;
`

const ModalBody = styled(Modal.Body)`
    background-color: #004E95;
    color: white;
    border: 1px solid white;
    width: 700px;
`

//Modal Data

const EditDiv = styled.tr`
    border-bottom: 1px solid white;
    margin-bottom: 15px;
    display: block;
    overflow: hidden;
`

const EditLabel = styled.h5`
    margin: 0px;
    display: inline;
    float: left;
`

const AddLabel = styled.div`
    margin: 0px;
    display: inline;
    float: left;
`

const ButtonsContainer = styled.div`
    text-align: center;
    margin-top: 25px;
`

const InputEdit = styled.input`
    float: right;
    margin-bottom: 15px;
    width: 80%; 
    background: none;
    border: none;
    border-bottom: 1px solid white;
    padding: 5px;
    color: white;
    &::placeholder{
        color: white;
    };
    &:focus{
        outline: none
    }
`

const SubmitButton = styled.button`
    height: 40px;    
    width: 80px;
    margin-left: 20%;
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B
`

export default Affiliates