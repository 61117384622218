import styled from "styled-components"
import { Modal } from 'react-bootstrap'
import {useState} from 'react'

import { analytics, eventLog } from "../firebase";

import OrderModalTable from "./OrderSubPages/OrderModalTable";
import OrderModalOtherInfo from "./OrderSubPages/OrderModalOtherInfo";

import "bootstrap/dist/css/bootstrap.css";
import './styles/pricestyle.css'

const OrderButton = () =>{
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => {
        setShow(true);
        eventLog(analytics, 'Opened_Order_Page')
    }

    return(
    <>
        <OrderBtn onClick={handleOpen}>Order Units</OrderBtn>
        <Modal show={show} onHide={handleClose} id="orderModal" contentClassName="modal-height" dialogClassName="modal-width" size="lg" centered>
            <Modal.Header closeButton closeVariant="white" id="modalHeader">
                <Modal.Title id="modalTitle">Place an Order</Modal.Title>
            </Modal.Header>
            <Modal.Body id="modalBody">
                <OrderModalTable /> 
                <OrderModalOtherInfo />
            </Modal.Body>
        </Modal>
    </>
    )
}

const OrderBtn = styled.button`
    width: 225px;
    height: 100px;
    font-size: 24px;
    border-radius: 5px;
    border: 2px solid white;
    background-color: rgba(0,0,0,0.3);
    color: white;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
    @media(max-width: 1300px){
        width: 125px;
        height: 75px;
        color: white;
        font-size: 18px;
    }
`


export default OrderButton