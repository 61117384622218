const ohioCountiesByName = []
const kentuckyCountiesByName = []

//Ohio Counties
export const BrownCounty = [
    {lat: 38.771268, lng: -84.052750},
    {lat: 38.771518, lng: -84.050581},
    {lat: 38.770483, lng: -84.044534},
    {lat: 38.776406, lng: -84.017602},
    {lat: 38.781081, lng: -83.999586},
    {lat: 38.787533, lng: -83.978616},
    {lat: 38.786900, lng: -83.959688},
    {lat: 38.783324, lng: -83.943882},
    {lat: 38.773359, lng: -83.926220},
    {lat: 38.772120, lng: -83.928738},
    {lat: 38.769468, lng: -83.917209},
    {lat: 38.754180, lng: -83.855753},
    {lat: 38.747262, lng: -83.848507},
    {lat: 38.722507, lng: -83.840635},
    {lat: 38.710127, lng: -83.822894},
    {lat: 38.700669, lng: -83.788036},
    {lat: 38.695696, lng: -83.783538},
    {lat: 38.658827, lng: -83.772793},
    {lat: 38.652193, lng: -83.763423},
    {lat: 38.646728, lng: -83.720318},
    {lat: 38.641752, lng: -83.713197},
    {lat: 38.641752, lng: -83.710698},
    {lat: 38.640816, lng: -83.709854},
    {lat: 38.640091, lng: -83.705323},
    {lat: 39.020134, lng: -83.674083},
    {lat: 39.021277, lng: -83.872211},
    {lat: 39.247440, lng: -83.865915},
    {lat: 39.254097, lng: -83.989942},
]
ohioCountiesByName.push({state: "Ohio", name: "Brown"})

export const ButlerCounty = [
    {lat: 39.305190, lng: -84.819458},
    {lat: 39.304999, lng: -84.641591},
    {lat: 39.309595, lng: -84.638627},
    {lat: 39.312043, lng: -84.630614},
    {lat: 39.308595, lng: -84.598794},
    {lat: 39.308153, lng: -84.562053},
    {lat: 39.306198, lng: -84.542205},
    {lat: 39.303257, lng: -84.523566},
    {lat: 39.305945, lng: -84.504734},
    {lat: 39.301561, lng: -84.485086},
    {lat: 39.302943, lng: -84.465488},
    {lat: 39.298639, lng: -84.427977},
    {lat: 39.298244, lng: -84.410274},
    {lat: 39.297859, lng: -84.409317},
    {lat: 39.297585, lng: -84.391133},
    {lat: 39.292285, lng: -84.353409},
    {lat: 39.456218, lng: -84.336711},
    {lat: 39.456524, lng: -84.340212},
    {lat: 39.478850, lng: -84.337732},
    {lat: 39.548793, lng: -84.332211},
    {lat: 39.541842, lng: -84.361959},
    {lat: 39.543846, lng: -84.364774},
    {lat: 39.589747, lng: -84.365279},
    {lat: 39.590174, lng: -84.474393},
    {lat: 39.591015, lng: -84.479031},
    {lat: 39.568868, lng: -84.479031},
    {lat: 39.567656, lng: -84.629778},
    {lat: 39.567980, lng: -84.632951},
    {lat: 39.566459, lng: -84.763235},
    {lat: 39.567744, lng: -84.814925},
    {lat: 39.305427, lng: -84.818788}
]
ohioCountiesByName.push({state: "Ohio", name: "Butler"})

export const ClintonCounty = [
    {lat: 39.254097, lng: -83.989942},
    {lat: 39.255142, lng: -84.006792},
    {lat: 39.569223, lng: -83.976962},
    {lat: 39.544193, lng: -83.576515},
    {lat: 39.378345, lng: -83.591749},
    {lat: 39.262848, lng: -83.784774},
    {lat: 39.261904, lng: -83.784851},
    {lat: 39.261094, lng: -83.784845},
    {lat: 39.260162, lng: -83.785379},
    {lat: 39.259185, lng: -83.785459},
    {lat: 39.258058, lng: -83.786397},
    {lat: 39.257825, lng: -83.786380},
    {lat: 39.257508, lng: -83.786505},
    {lat: 39.257200, lng: -83.786431},
    {lat: 39.256623, lng: -83.786834},
    {lat: 39.256000, lng: -83.786420},
    {lat: 39.254993, lng: -83.787265},
    {lat: 39.254815, lng: -83.787609},
    {lat: 39.254697, lng: -83.787653},
    {lat: 39.254600, lng: -83.787650},
    {lat: 39.254448, lng: -83.787755},
    {lat: 39.254155, lng: -83.788235},
    {lat: 39.253841, lng: -83.789242},
    {lat: 39.253501, lng: -83.789627},
    {lat: 39.253022, lng: -83.790269},
    {lat: 39.252873, lng: -83.790225},
    {lat: 39.252609, lng: -83.789880},
    {lat: 39.251889, lng: -83.789664},
    {lat: 39.251387, lng: -83.789772},
    {lat: 39.250714, lng: -83.790117},
    {lat: 39.250547, lng: -83.790147},
    {lat: 39.250267, lng: -83.790458},
    {lat: 39.250154, lng: -83.791560},
    {lat: 39.249238, lng: -83.792668},
    {lat: 39.248702, lng: -83.792726},
    {lat: 39.245169, lng: -83.794510},
    {lat: 39.244501, lng: -83.794568},
    {lat: 39.243554, lng: -83.795230},
    {lat: 39.242243, lng: -83.795325},
    {lat: 39.242209, lng: -83.795257},
    {lat: 39.241994, lng: -83.795264},
    {lat: 39.241811, lng: -83.795450},
    {lat: 39.241049, lng: -83.795581},
    {lat: 39.241007, lng: -83.795646},
    {lat: 39.240937, lng: -83.795649},
    {lat: 39.240863, lng: -83.795916},
    {lat: 39.239311, lng: -83.798608},
    {lat: 39.237581, lng: -83.801052},
    {lat: 39.237698, lng: -83.802105},
    {lat: 39.237614, lng: -83.802396},
    {lat: 39.237389, lng: -83.802514},
    {lat: 39.237127, lng: -83.802491},
    {lat: 39.235928, lng: -83.802102},
    {lat: 39.235253, lng: -83.802146},
    {lat: 39.234897, lng: -83.801957},
    {lat: 39.234057, lng: -83.801967},
    {lat: 39.233826, lng: -83.802501},
    {lat: 39.233554, lng: -83.802575},
    {lat: 39.232947, lng: -83.802224},
    {lat: 39.231581, lng: -83.801345},
    {lat: 39.231125, lng: -83.801501},
    {lat: 39.230958, lng: -83.801697},
    {lat: 39.230853, lng: -83.803684},
    {lat: 39.231062, lng: -83.803974},
    {lat: 39.231397, lng: -83.804190},
    {lat: 39.231617, lng: -83.804616},
    {lat: 39.231554, lng: -83.805137},
    {lat: 39.231219, lng: -83.805394},
    {lat: 39.228877, lng: -83.807075},
    {lat: 39.225341, lng: -83.807887},
    {lat: 39.224572, lng: -83.808554},
    {lat: 39.223747, lng: -83.810828},
    {lat: 39.223789, lng: -83.811730},
    {lat: 39.223468, lng: -83.812560},
    {lat: 39.223496, lng: -83.813156},
    {lat: 39.223048, lng: -83.813859},
    {lat: 39.222881, lng: -83.818118},
    {lat: 39.222937, lng: -83.818695},
    {lat: 39.222832, lng: -83.818876},
    {lat: 39.223326, lng: -83.835904},
    {lat: 39.245748, lng: -83.834391}
]
ohioCountiesByName.push({state: "Ohio", name: "Clinton"})

export const ClermontCounty = [
    {lat: 39.254097, lng: -83.989942},
    {lat: 38.771268, lng: -84.052750},
    {lat: 38.770374, lng: -84.070676},
    {lat: 38.773903, lng: -84.086669},
    {lat: 38.779236, lng: -84.109020},
    {lat: 38.780840, lng: -84.111656},
    {lat: 38.780966, lng: -84.110198},
    {lat: 38.795111, lng: -84.156820},
    {lat: 38.800866, lng: -84.198197},
    {lat: 38.805776, lng: -84.213091},
    {lat: 38.819435, lng: -84.226266},
    {lat: 38.840052, lng: -84.232911},
    {lat: 38.873776, lng: -84.231766},
    {lat: 38.893219, lng: -84.234401},
    {lat: 38.923531, lng: -84.257200},
    {lat: 38.954632, lng: -84.287447},
    {lat: 38.968262, lng: -84.295238},
    {lat: 38.990261, lng: -84.297414},
    {lat: 39.013143, lng: -84.310246},
    {lat: 39.022845, lng: -84.319641},
    {lat: 39.151959, lng: -84.301975},
    {lat: 39.159449, lng: -84.301798},
    {lat: 39.164995, lng: -84.299567},
    {lat: 39.167176, lng: -84.295030},
    {lat: 39.168579, lng: -84.295997},
    {lat: 39.170482, lng: -84.297956},
    {lat: 39.174256, lng: -84.299567},
    {lat: 39.180082, lng: -84.292919},
    {lat: 39.181955, lng: -84.288609},
    {lat: 39.184780, lng: -84.288609},
    {lat: 39.186509, lng: -84.288287},
    {lat: 39.188632, lng: -84.287331},
    {lat: 39.194195, lng: -84.281792},
    {lat: 39.197480, lng: -84.277627},
    {lat: 39.199981, lng: -84.277264},
    {lat: 39.201054, lng: -84.276263},
    {lat: 39.204877, lng: -84.276784},
    {lat: 39.211043, lng: -84.282188},
    {lat: 39.211581, lng: -84.282089},
    {lat: 39.213425, lng: -84.284841},
    {lat: 39.213540, lng: -84.287618},
    {lat: 39.211485, lng: -84.292030},
    {lat: 39.208949, lng: -84.300038},
    {lat: 39.209237, lng: -84.302170},
    {lat: 39.208757, lng: -84.305120},
    {lat: 39.208604, lng: -84.307451},
    {lat: 39.208776, lng: -84.309558},
    {lat: 39.209810, lng: -84.311230},
    {lat: 39.212176, lng: -84.313103},
    {lat: 39.220685, lng: -84.316301},
    {lat: 39.221530, lng: -84.317541},
    {lat: 39.221991, lng: -84.319499},
    {lat: 39.223451, lng: -84.320094},
    {lat: 39.225314, lng: -84.318458},
    {lat: 39.231363, lng: -84.309211},
    {lat: 39.232938, lng: -84.307946},
    {lat: 39.234493, lng: -84.307004},
    {lat: 39.234378, lng: -84.303608},
    {lat: 39.233936, lng: -84.302641},
    {lat: 39.235127, lng: -84.299319},
    {lat: 39.238333, lng: -84.296989},
    {lat: 39.239562, lng: -84.296394},
    {lat: 39.242289, lng: -84.296468},
    {lat: 39.246762, lng: -84.295080},
    {lat: 39.249047, lng: -84.292700},
    {lat: 39.251523, lng: -84.283701},
    {lat: 39.257513, lng: -84.272966},
    {lat: 39.261947, lng: -84.267834},
    {lat: 39.264107, lng: -84.264417},
    {lat: 39.264084, lng: -84.262803},
    {lat: 39.266098, lng: -84.260842},
    {lat: 39.267762, lng: -84.260869},
    {lat: 39.270804, lng: -84.259370}
]
ohioCountiesByName.push({state: "Ohio", name: "Clermont"})

export const HamiltonCounty = [
    {lat: 39.270095, lng: -84.819628},
    {lat: 39.270034, lng: -84.819840},
    {lat: 39.247717, lng: -84.820078},
    {lat: 39.244390, lng: -84.820086},
    {lat: 39.243780, lng: -84.819821},
    {lat: 39.158505, lng: -84.819821},
    {lat: 39.156506, lng: -84.820294},
    {lat: 39.149095, lng: -84.820301},
    {lat: 39.147932, lng: -84.820097},
    {lat: 39.105419, lng: -84.820154},
    {lat: 39.106948, lng: -84.812331},
    {lat: 39.109013, lng: -84.810957},
    {lat: 39.113542, lng: -84.791560},
    {lat: 39.120068, lng: -84.782547},
    {lat: 39.138588, lng: -84.766861},
    {lat: 39.146700, lng: -84.754321},
    {lat: 39.147473, lng: -84.745252},
    {lat: 39.147039, lng: -84.744548},
    {lat: 39.147531, lng: -84.744026},
    {lat: 39.141990, lng: -84.726199},
    {lat: 39.100438, lng: -84.684963},
    {lat: 39.098183, lng: -84.677010},
    {lat: 39.098005, lng: -84.666687},
    {lat: 39.091239, lng: -84.649865},
    {lat: 39.076754, lng: -84.632462},
    {lat: 39.076090, lng: -84.628709},
    {lat: 39.074342, lng: -84.623503},
    {lat: 39.073307, lng: -84.618372},
    {lat: 39.073461, lng: -84.613784},
    {lat: 39.073468, lng: -84.605852},
    {lat: 39.074521, lng: -84.600792},
    {lat: 39.076415, lng: -84.597344},
    {lat: 39.077604, lng: -84.593682},
    {lat: 39.078485, lng: -84.589688},
    {lat: 39.079575, lng: -84.584776},
    {lat: 39.080585, lng: -84.579213},
    {lat: 39.081080, lng: -84.576572},
    {lat: 39.082190, lng: -84.572592},
    {lat: 39.083418, lng: -84.570129},
    {lat: 39.088757, lng: -84.563138},
    {lat: 39.094319, lng: -84.558439},
    {lat: 39.098283, lng: -84.553868},
    {lat: 39.098965, lng: -84.552538},
    {lat: 39.099598, lng: -84.550531},
    {lat: 39.099964, lng: -84.545521},
    {lat: 39.099540, lng: -84.543772},
    {lat: 39.099556, lng: -84.542292},
    {lat: 39.097136, lng: -84.535654},
    {lat: 39.092735, lng: -84.526612},
    {lat: 39.092241, lng: -84.523505},
    {lat: 39.092474, lng: -84.518956},
    {lat: 39.093848, lng: -84.510437},
    {lat: 39.095038, lng: -84.506116},
    {lat: 39.096763, lng: -84.501929},
    {lat: 39.102466, lng: -84.493732},
    {lat: 39.111323, lng: -84.487232},
    {lat: 39.116761, lng: -84.480953},
    {lat: 39.121455, lng: -84.470603},
    {lat: 39.121788, lng: -84.461977},
    {lat: 39.120490, lng: -84.455368},
    {lat: 39.114430, lng: -84.445154},
    {lat: 39.103241, lng: -84.436227},
    {lat: 39.094272, lng: -84.432840},
    {lat: 39.092451, lng: -84.433958},
    {lat: 39.086868, lng: -84.434637},
    {lat: 39.083916, lng: -84.432928},
    {lat: 39.078036, lng: -84.432698},
    {lat: 39.070330, lng: -84.432902},
    {lat: 39.057949, lng: -84.429668},
    {lat: 39.056720, lng: -84.428111},
    {lat: 39.053807, lng: -84.426606},
    {lat: 39.045623, lng: -84.406906},
    {lat: 39.045655, lng: -84.402548},
    {lat: 39.046363, lng: -84.400943},
    {lat: 39.045257, lng: -84.394135},
    {lat: 39.045158, lng: -84.386850},
    {lat: 39.041357, lng: -84.360441},
    {lat: 39.027452, lng: -84.326523},
    {lat: 39.022845, lng: -84.319641},
    {lat: 39.151959, lng: -84.301975},
    {lat: 39.159449, lng: -84.301798},
    {lat: 39.164995, lng: -84.299567},
    {lat: 39.167176, lng: -84.295030},
    {lat: 39.168579, lng: -84.295997},
    {lat: 39.170482, lng: -84.297956},
    {lat: 39.174256, lng: -84.299567},
    {lat: 39.180082, lng: -84.292919},
    {lat: 39.181955, lng: -84.288609},
    {lat: 39.184780, lng: -84.288609},
    {lat: 39.186509, lng: -84.288287},
    {lat: 39.188632, lng: -84.287331},
    {lat: 39.194195, lng: -84.281792},
    {lat: 39.197480, lng: -84.277627},
    {lat: 39.199981, lng: -84.277264},
    {lat: 39.201054, lng: -84.276263},
    {lat: 39.204877, lng: -84.276784},
    {lat: 39.211043, lng: -84.282188},
    {lat: 39.211581, lng: -84.282089},
    {lat: 39.213425, lng: -84.284841},
    {lat: 39.213540, lng: -84.287618},
    {lat: 39.211485, lng: -84.292030},
    {lat: 39.208949, lng: -84.300038},
    {lat: 39.209237, lng: -84.302170},
    {lat: 39.208757, lng: -84.305120},
    {lat: 39.208604, lng: -84.307451},
    {lat: 39.208776, lng: -84.309558},
    {lat: 39.209810, lng: -84.311230},
    {lat: 39.212176, lng: -84.313103},
    {lat: 39.220685, lng: -84.316301},
    {lat: 39.221530, lng: -84.317541},
    {lat: 39.221991, lng: -84.319499},
    {lat: 39.223451, lng: -84.320094},
    {lat: 39.225314, lng: -84.318458},
    {lat: 39.231363, lng: -84.309211},
    {lat: 39.232938, lng: -84.307946},
    {lat: 39.234493, lng: -84.307004},
    {lat: 39.234378, lng: -84.303608},
    {lat: 39.233936, lng: -84.302641},
    {lat: 39.235127, lng: -84.299319},
    {lat: 39.238333, lng: -84.296989},
    {lat: 39.239562, lng: -84.296394},
    {lat: 39.242289, lng: -84.296468},
    {lat: 39.246762, lng: -84.295080},
    {lat: 39.249047, lng: -84.292700},
    {lat: 39.251523, lng: -84.283701},
    {lat: 39.257513, lng: -84.272966},
    {lat: 39.261947, lng: -84.267834},
    {lat: 39.264107, lng: -84.264417},
    {lat: 39.264084, lng: -84.262803},
    {lat: 39.266098, lng: -84.260842},
    {lat: 39.267762, lng: -84.260869},
    {lat: 39.270804, lng: -84.259370},
    {lat: 39.274143, lng: -84.258543},
    {lat: 39.275027, lng: -84.256765},
    {lat: 39.275505, lng: -84.256474},
    {lat: 39.276642, lng: -84.256396},
    {lat: 39.277600, lng: -84.257193},
    {lat: 39.280396, lng: -84.264901},
    {lat: 39.282178, lng: -84.265773},
    {lat: 39.282853, lng: -84.265762},
    {lat: 39.285382, lng: -84.264590},
    {lat: 39.288131, lng: -84.262545},
    {lat: 39.288385, lng: -84.262920},
    {lat: 39.290874, lng: -84.289486},
    {lat: 39.290858, lng: -84.290320},
    {lat: 39.291687, lng: -84.296629},
    {lat: 39.292013, lng: -84.306861},
    {lat: 39.292352, lng: -84.307861},
    {lat: 39.293299, lng: -84.334074},
    {lat: 39.292285, lng: -84.353409},
    {lat: 39.297585, lng: -84.391133},
    {lat: 39.297859, lng: -84.409317},
    {lat: 39.298244, lng: -84.410274},
    {lat: 39.298639, lng: -84.427977},
    {lat: 39.302943, lng: -84.465488},
    {lat: 39.301561, lng: -84.485086},
    {lat: 39.305945, lng: -84.504734},
    {lat: 39.303257, lng: -84.523566},
    {lat: 39.306198, lng: -84.542205},
    {lat: 39.308153, lng: -84.562053},
    {lat: 39.308595, lng: -84.598794},
    {lat: 39.312043, lng: -84.630614},
    {lat: 39.309595, lng: -84.638627},
    {lat: 39.304999, lng: -84.641591},
    {lat: 39.305190, lng: -84.819458},
]
ohioCountiesByName.push({state: "Ohio", name: "Hamilton"})

export const HighlandCounty = [
    {lat: 39.378345, lng: -83.591749},
    {lat: 39.262848, lng: -83.784774},
    {lat: 39.261904, lng: -83.784851},
    {lat: 39.261094, lng: -83.784845},
    {lat: 39.260162, lng: -83.785379},
    {lat: 39.259185, lng: -83.785459},
    {lat: 39.258058, lng: -83.786397},
    {lat: 39.257825, lng: -83.786380},
    {lat: 39.257508, lng: -83.786505},
    {lat: 39.257200, lng: -83.786431},
    {lat: 39.256623, lng: -83.786834},
    {lat: 39.256000, lng: -83.786420},
    {lat: 39.254993, lng: -83.787265},
    {lat: 39.254815, lng: -83.787609},
    {lat: 39.254697, lng: -83.787653},
    {lat: 39.254600, lng: -83.787650},
    {lat: 39.254448, lng: -83.787755},
    {lat: 39.254155, lng: -83.788235},
    {lat: 39.253841, lng: -83.789242},
    {lat: 39.253501, lng: -83.789627},
    {lat: 39.253022, lng: -83.790269},
    {lat: 39.252873, lng: -83.790225},
    {lat: 39.252609, lng: -83.789880},
    {lat: 39.251889, lng: -83.789664},
    {lat: 39.251387, lng: -83.789772},
    {lat: 39.250714, lng: -83.790117},
    {lat: 39.250547, lng: -83.790147},
    {lat: 39.250267, lng: -83.790458},
    {lat: 39.250154, lng: -83.791560},
    {lat: 39.249238, lng: -83.792668},
    {lat: 39.248702, lng: -83.792726},
    {lat: 39.245169, lng: -83.794510},
    {lat: 39.244501, lng: -83.794568},
    {lat: 39.243554, lng: -83.795230},
    {lat: 39.242243, lng: -83.795325},
    {lat: 39.242209, lng: -83.795257},
    {lat: 39.241994, lng: -83.795264},
    {lat: 39.241811, lng: -83.795450},
    {lat: 39.241049, lng: -83.795581},
    {lat: 39.241007, lng: -83.795646},
    {lat: 39.240937, lng: -83.795649},
    {lat: 39.240863, lng: -83.795916},
    {lat: 39.239311, lng: -83.798608},
    {lat: 39.237581, lng: -83.801052},
    {lat: 39.237698, lng: -83.802105},
    {lat: 39.237614, lng: -83.802396},
    {lat: 39.237389, lng: -83.802514},
    {lat: 39.237127, lng: -83.802491},
    {lat: 39.235928, lng: -83.802102},
    {lat: 39.235253, lng: -83.802146},
    {lat: 39.234897, lng: -83.801957},
    {lat: 39.234057, lng: -83.801967},
    {lat: 39.233826, lng: -83.802501},
    {lat: 39.233554, lng: -83.802575},
    {lat: 39.232947, lng: -83.802224},
    {lat: 39.231581, lng: -83.801345},
    {lat: 39.231125, lng: -83.801501},
    {lat: 39.230958, lng: -83.801697},
    {lat: 39.230853, lng: -83.803684},
    {lat: 39.231062, lng: -83.803974},
    {lat: 39.231397, lng: -83.804190},
    {lat: 39.231617, lng: -83.804616},
    {lat: 39.231554, lng: -83.805137},
    {lat: 39.231219, lng: -83.805394},
    {lat: 39.228877, lng: -83.807075},
    {lat: 39.225341, lng: -83.807887},
    {lat: 39.224572, lng: -83.808554},
    {lat: 39.223747, lng: -83.810828},
    {lat: 39.223789, lng: -83.811730},
    {lat: 39.223468, lng: -83.812560},
    {lat: 39.223496, lng: -83.813156},
    {lat: 39.223048, lng: -83.813859},
    {lat: 39.222881, lng: -83.818118},
    {lat: 39.222937, lng: -83.818695},
    {lat: 39.222832, lng: -83.818876},
    {lat: 39.223326, lng: -83.835904},
    {lat: 39.245748, lng: -83.834391},
    {lat: 39.247440, lng: -83.865915},
    {lat: 39.021277, lng: -83.872211},
    {lat: 39.020134, lng: -83.674083},
    {lat: 39.055203, lng: -83.385712},
    {lat: 39.055196, lng: -83.385639},
    {lat: 39.100051, lng: -83.374907},
    {lat: 39.100400, lng: -83.374915},
    {lat: 39.130514, lng: -83.368041},
    {lat: 39.130566, lng: -83.367983},
    {lat: 39.233123, lng: -83.343509},
    {lat: 39.232529, lng: -83.347594},
    {lat: 39.232620, lng: -83.347859},
    {lat: 39.232780, lng: -83.348034},
    {lat: 39.233101, lng: -83.348094},
    {lat: 39.237608, lng: -83.346711},
    {lat: 39.239459, lng: -83.345746},
    {lat: 39.240308, lng: -83.345659},
    {lat: 39.242412, lng: -83.346040},
    {lat: 39.243397, lng: -83.346365},
    {lat: 39.244539, lng: -83.346953},
    {lat: 39.244974, lng: -83.347073},
    {lat: 39.245776, lng: -83.347073},
    {lat: 39.247010, lng: -83.346602},
    {lat: 39.247493, lng: -83.346248},
    {lat: 39.248016, lng: -83.345603},
    {lat: 39.248725, lng: -83.345103},
    {lat: 39.249184, lng: -83.344927},    
    {lat: 39.250067, lng: -83.344925},
    {lat: 39.250604, lng: -83.345009},
    {lat: 39.251472, lng: -83.345648},
    {lat: 39.252645, lng: -83.346750},
    {lat: 39.252817, lng: -83.347867},
    {lat: 39.252664, lng: -83.348782},
    {lat: 39.250310, lng: -83.354409},
    {lat: 39.250371, lng: -83.355393},
    {lat: 39.250055, lng: -83.355752},
    {lat: 39.249316, lng: -83.355983},
    {lat: 39.248200, lng: -83.355477},
    {lat: 39.247678, lng: -83.355521},
    {lat: 39.242211, lng: -83.359409},
    {lat: 39.240939, lng: -83.361033},
    {lat: 39.240638, lng: -83.361796},
    {lat: 39.240577, lng: -83.362352},
    {lat: 39.241679, lng: -83.365811},
    {lat: 39.243460, lng: -83.368761},
    {lat: 39.245391, lng: -83.370688},
    {lat: 39.247432, lng: -83.371860},
    {lat: 39.248439, lng: -83.373739},
    {lat: 39.248513, lng: -83.375136},
    {lat: 39.248457, lng: -83.376870},
    {lat: 39.249129, lng: -83.378749},
    {lat: 39.250063, lng: -83.381436},
    {lat: 39.250852, lng: -83.381494},
    {lat: 39.251175, lng: -83.381451},
    {lat: 39.251633, lng: -83.381332},
    {lat: 39.252116, lng: -83.380934},
    {lat: 39.252591, lng: -83.380350},
    {lat: 39.253072, lng: -83.379952},
    {lat: 39.253586, lng: -83.379649},
    {lat: 39.254609, lng: -83.379265},
    {lat: 39.255601, lng: -83.378790},
    {lat: 39.256510, lng: -83.378191},
    {lat: 39.257646, lng: -83.377922},
    {lat: 39.258606, lng: -83.378029},
    {lat: 39.259457, lng: -83.378550},
    {lat: 39.261600, lng: -83.380023},
    {lat: 39.262341, lng: -83.380907},
    {lat: 39.262746, lng: -83.381749},
    {lat: 39.262958, lng: -83.382697},
    {lat: 39.263708, lng: -83.386678},
    {lat: 39.267267, lng: -83.393506},
    {lat: 39.268589, lng: -83.393855},
    {lat: 39.270139, lng: -83.393604},
    {lat: 39.272589, lng: -83.393991},
    {lat: 39.274873, lng: -83.393157},
    {lat: 39.277680, lng: -83.384880},
    {lat: 39.278383, lng: -83.381291},
    {lat: 39.281484, lng: -83.381473},
    {lat: 39.284141, lng: -83.382108},
    {lat: 39.290978, lng: -83.376621},
    {lat: 39.292281, lng: -83.376659},
    {lat: 39.292780, lng: -83.377106},
    {lat: 39.293385, lng: -83.378700},
    {lat: 39.293966, lng: -83.381704},
    {lat: 39.294342, lng: -83.382136},
    {lat: 39.297471, lng: -83.382842},
    {lat: 39.299878, lng: -83.382804},
    {lat: 39.302455, lng: -83.382364},
    {lat: 39.303436, lng: -83.381787},
    {lat: 39.304428, lng: -83.381689},
    {lat: 39.304962, lng: -83.381916},
    {lat: 39.305813, lng: -83.382682},
    {lat: 39.306148, lng: -83.382804},
    {lat: 39.306882, lng: -83.383267},
    {lat: 39.307903, lng: -83.384253},
    {lat: 39.308420, lng: -83.384473},
    {lat: 39.311008, lng: -83.383578},
    {lat: 39.312710, lng: -83.383494},
    {lat: 39.313591, lng: -83.383176},
    {lat: 39.314636, lng: -83.383327},
    {lat: 39.315569, lng: -83.383305},
    {lat: 39.316203, lng: -83.383145},
    {lat: 39.316555, lng: -83.383206},
    {lat: 39.317987, lng: -83.385110},
    {lat: 39.318192, lng: -83.385171},
    {lat: 39.318492, lng: -83.385489},
    {lat: 39.318903, lng: -83.385649},
    {lat: 39.319677, lng: -83.385747},
    {lat: 39.320616, lng: -83.386233},
    {lat: 39.321638, lng: -83.386741},
    {lat: 39.325376, lng: -83.386180},
    {lat: 39.325699, lng: -83.385929},
    {lat: 39.326127, lng: -83.385042},
    {lat: 39.326456, lng: -83.383820},
    {lat: 39.326644, lng: -83.383684},
    {lat: 39.326726, lng: -83.382971},
    {lat: 39.327336, lng: -83.382645},
    {lat: 39.327336, lng: -83.382645},
    {lat: 39.329713, lng: -83.383426},
    {lat: 39.329900, lng: -83.383426},
    {lat: 39.329947, lng: -83.383540},
    {lat: 39.330564, lng: -83.383707},
    {lat: 39.331813, lng: -83.383775},
    {lat: 39.334260, lng: -83.383320},
    {lat: 39.335182, lng: -83.382720},
    {lat: 39.337018, lng: -83.382607},
    {lat: 39.339799, lng: -83.381575},
    {lat: 39.340181, lng: -83.381264},
    {lat: 39.342381, lng: -83.378601},
    {lat: 39.343971, lng: -83.377114},
    {lat: 39.347198, lng: -83.374914},
    {lat: 39.349005, lng: -83.375263},
    {lat: 39.355088, lng: -83.379520},
    {lat: 39.356557, lng: -83.380074},
    {lat: 39.357178, lng: -83.379961},
    {lat: 39.359503, lng: -83.378480},
    {lat: 39.362301, lng: -83.377191},
    {lat: 39.362397, lng: -83.376908},
    {lat: 39.364171, lng: -83.376478},
    {lat: 39.365072, lng: -83.375936},
    {lat: 39.367144, lng: -83.374240},
    {lat: 39.368665, lng: -83.373369},
    {lat: 39.371409, lng: -83.373878},
    {lat: 39.372117, lng: -83.374409},
    {lat: 39.374372, lng: -83.374432},
    {lat: 39.374678, lng: -83.374273},
    {lat: 39.374696, lng: -83.374138},
    {lat: 39.375063, lng: -83.373991},
    {lat: 39.376321, lng: -83.372329},
    {lat: 39.376802, lng: -83.372249},
    {lat: 39.377126, lng: -83.372442},
    {lat: 39.377362, lng: -83.372781},
    {lat: 39.377530, lng: -83.375402}
]
ohioCountiesByName.push({state: "Ohio", name: "Highland"})

export const WarrenCounty = [
    {lat: 39.290874, lng: -84.289486},
    {lat: 39.290858, lng: -84.290320},
    {lat: 39.291687, lng: -84.296629},
    {lat: 39.292013, lng: -84.306861},
    {lat: 39.292352, lng: -84.307861},
    {lat: 39.293299, lng: -84.334074},
    {lat: 39.292285, lng: -84.353409},
    {lat: 39.456218, lng: -84.336711},
    {lat: 39.456524, lng: -84.340212},
    {lat: 39.478850, lng: -84.337732},
    {lat: 39.548793, lng: -84.332211},
    {lat: 39.541842, lng: -84.361959},
    {lat: 39.543846, lng: -84.364774},
    {lat: 39.589747, lng: -84.365279},
    {lat: 39.589218, lng: -84.285762},
    {lat: 39.581190, lng: -84.163090},
    {lat: 39.569223, lng: -83.976962},
    {lat: 39.255142, lng: -84.006792},
    {lat: 39.262989, lng: -84.133938},
    {lat: 39.264350, lng: -84.155818},
    {lat: 39.270804, lng: -84.259370},
    {lat: 39.274143, lng: -84.258543},
    {lat: 39.275027, lng: -84.256765},
    {lat: 39.275505, lng: -84.256474},
    {lat: 39.276642, lng: -84.256396},
    {lat: 39.276642, lng: -84.256396},
    {lat: 39.277605, lng: -84.257195},
    {lat: 39.280396, lng: -84.264901},
    {lat: 39.282178, lng: -84.265773},
    {lat: 39.282859, lng: -84.265762},
    {lat: 39.285382, lng: -84.264590},
    {lat: 39.288131, lng: -84.262545},
    {lat: 39.288385, lng: -84.262920},
];
ohioCountiesByName.push({state: "Ohio", name: "Warren"})

//Kentucky Counties
export const BooneCounty = [
    {lat: 38.777318, lng: -84.660070},
    {lat: 38.777712, lng: -84.655043},
    {lat: 38.777910, lng: -84.654925},
    {lat: 38.778378, lng: -84.654873},
    {lat: 38.778972, lng: -84.654934},
    {lat: 38.779194, lng: -84.655033},
    {lat: 38.779421, lng: -84.655211},
    {lat: 38.779614, lng: -84.655473},
    {lat: 38.779834, lng: -84.656040},
    {lat: 38.779871, lng: -84.656776},
    {lat: 38.779915, lng: -84.656910},
    {lat: 38.780147, lng: -84.656994},
    {lat: 38.781177, lng: -84.656659},
    {lat: 38.781430, lng: -84.656320},
    {lat: 38.781660, lng: -84.656151},
    {lat: 38.782345, lng: -84.656024},
    {lat: 38.784828, lng: -84.657083},
    {lat: 38.785688, lng: -84.657661},
    {lat: 38.786118, lng: -84.657491},
    {lat: 38.786824, lng: -84.655770},
    {lat: 38.787428, lng: -84.655668},
    {lat: 38.787585, lng: -84.655791},
    {lat: 38.789208, lng: -84.655941},
    {lat: 38.789552, lng: -84.656079},
    {lat: 38.790094, lng: -84.656106},
    {lat: 38.790172, lng: -84.655565},
    {lat: 38.790232, lng: -84.655516},
    {lat: 38.791105, lng: -84.651642},
    {lat: 38.792179, lng: -84.649055},
    {lat: 38.792722, lng: -84.645942},
    {lat: 38.793408, lng: -84.645102},
    {lat: 38.795485, lng: -84.643841},
    {lat: 38.797799, lng: -84.643263},
    {lat: 38.798894, lng: -84.641609},
    {lat: 38.799569, lng: -84.638759},
    {lat: 38.800992, lng: -84.632928},
    {lat: 38.800951, lng: -84.632390},
    {lat: 38.801790, lng: -84.631286},
    {lat: 38.801954, lng: -84.631339},
    {lat: 38.802681, lng: -84.630420},
    {lat: 38.802619, lng: -84.629487},
    {lat: 38.802732, lng: -84.628949},
    {lat: 38.802527, lng: -84.627544},
    {lat: 38.802844, lng: -84.626979},
    {lat: 38.802844, lng: -84.626519},
    {lat: 38.803458, lng: -84.625219},
    {lat: 38.803377, lng: -84.622987},
    {lat: 38.803551, lng: -84.621936},
    {lat: 38.803182, lng: -84.620898},
    {lat: 38.803059, lng: -84.619651},
    {lat: 38.803387, lng: -84.618429},
    {lat: 38.803172, lng: -84.616998},
    {lat: 38.802404, lng: -84.616460},
    {lat: 38.802200, lng: -84.615803},
    {lat: 38.802275, lng: -84.615662},
    {lat: 38.879424, lng: -84.597930},
    {lat: 39.074342, lng: -84.623503},
    {lat: 39.076090, lng: -84.628709},
    {lat: 39.076754, lng: -84.632462},
    {lat: 39.091239, lng: -84.649865},
    {lat: 39.098005, lng: -84.666687},
    {lat: 39.098183, lng: -84.677010},
    {lat: 39.100438, lng: -84.684963},
    {lat: 39.141990, lng: -84.726199},
    {lat: 39.147531, lng: -84.744026},
    {lat: 39.147039, lng: -84.744548},
    {lat: 39.147473, lng: -84.745252},
    {lat: 39.146700, lng: -84.754321},
    {lat: 39.138588, lng: -84.766861},
    {lat: 39.120068, lng: -84.782547},
    {lat: 39.113542, lng: -84.791560},
    {lat: 39.109013, lng: -84.810957},
    {lat: 39.106948, lng: -84.812331},
    {lat: 39.105419, lng: -84.820154},
    {lat: 39.104084, lng: -84.826321},
    {lat: 39.100821, lng: -84.832672},
    {lat: 39.091428, lng: -84.844946},
    {lat: 39.080969, lng: -84.857821},
    {lat: 39.066643, lng: -84.887518},
    {lat: 39.058579, lng: -84.896445},
    {lat: 39.052847, lng: -84.897475},
    {lat: 39.049981, lng: -84.894471},
    {lat: 39.040048, lng: -84.888462},
    {lat: 39.031315, lng: -84.878249},
    {lat: 39.022980, lng: -84.867176},
    {lat: 39.000571, lng: -84.849581},
    {lat: 38.984227, lng: -84.835162},
    {lat: 38.969481, lng: -84.830098},
    {lat: 38.958737, lng: -84.834046},
    {lat: 38.954398, lng: -84.840483},
    {lat: 38.951429, lng: -84.843423},
    {lat: 38.948370, lng: -84.849266},
    {lat: 38.940786, lng: -84.856021},
    {lat: 38.931607, lng: -84.867822},
    {lat: 38.916048, lng: -84.879234},
    {lat: 38.909436, lng: -84.877174},
    {lat: 38.905562, lng: -84.874170},
    {lat: 38.900352, lng: -84.869879},
    {lat: 38.898081, lng: -84.861896},
    {lat: 38.897279, lng: -84.831512},
    {lat: 38.894874, lng: -84.814604},
    {lat: 38.895444, lng: -84.814749},
    {lat: 38.893366, lng: -84.807420},
    {lat: 38.893366, lng: -84.806738},
    {lat: 38.891131, lng: -84.800415},
    {lat: 38.887739, lng: -84.794637},
    {lat: 38.887401, lng: -84.793362},
    {lat: 38.880688, lng: -84.785477},
    {lat: 38.872833, lng: -84.784878},
    {lat: 38.861144, lng: -84.790363},
    {lat: 38.857322, lng: -84.794983},
    {lat: 38.854870, lng: -84.786646},
    {lat: 38.855069, lng: -84.782910},
    {lat: 38.857514, lng: -84.782405},
    {lat: 38.859397, lng: -84.779554},
    {lat: 38.858858, lng: -84.778293},
    {lat: 38.857251, lng: -84.778657},
    {lat: 38.854874, lng: -84.777947},
    {lat: 38.852962, lng: -84.778885},
    {lat: 38.849913, lng: -84.778156},
    {lat: 38.848324, lng: -84.778346},
    {lat: 38.847381, lng: -84.777774},
    {lat: 38.847063, lng: -84.776320},
    {lat: 38.845433, lng: -84.773232},
    {lat: 38.846466, lng: -84.770348},
    {lat: 38.849010, lng: -84.765908},
    {lat: 38.848712, lng: -84.764505},
    {lat: 38.848990, lng: -84.763127},
    {lat: 38.848752, lng: -84.762664},
    {lat: 38.848168, lng: -84.762437},
    {lat: 38.847987, lng: -84.761994},
    {lat: 38.848097, lng: -84.761221},
    {lat: 38.847682, lng: -84.760136},
    {lat: 38.846788, lng: -84.759688},
    {lat: 38.846045, lng: -84.759517},
    {lat: 38.844059, lng: -84.759488},
    {lat: 38.842210, lng: -84.758386},
    {lat: 38.840343, lng: -84.754831},
    {lat: 38.838671, lng: -84.753184},
    {lat: 38.835778, lng: -84.752536},
    {lat: 38.835384, lng: -84.752285},
    {lat: 38.834784, lng: -84.752319},
    {lat: 38.833684, lng: -84.752860},
    {lat: 38.833321, lng: -84.752873},
    {lat: 38.832801, lng: -84.752684},
    {lat: 38.832543, lng: -84.751576},
    {lat: 38.832832, lng: -84.751016},
    {lat: 38.832642, lng: -84.750746},
    {lat: 38.832145, lng: -84.750548},
    {lat: 38.831204, lng: -84.750934},
    {lat: 38.831075, lng: -84.750848},
    {lat: 38.777299, lng: -84.660100}
]
kentuckyCountiesByName.push({state: "Kentucky", name: "Boone"})

export const CampbellCounty = [

    {lat: 39.027452, lng: -84.326523},
    {lat: 39.041357, lng: -84.360441},
    {lat: 39.045158, lng: -84.386850},
    {lat: 39.045257, lng: -84.394135},
    {lat: 39.046363, lng: -84.400943},
    {lat: 39.045655, lng: -84.402548},
    {lat: 39.045623, lng: -84.406906},
    {lat: 39.053807, lng: -84.426606},
    {lat: 39.056720, lng: -84.428111},
    {lat: 39.057949, lng: -84.429668},
    {lat: 39.070330, lng: -84.432902},
    {lat: 39.078036, lng: -84.432698},
    {lat: 39.083916, lng: -84.432928},
    {lat: 39.086868, lng: -84.434637},
    {lat: 39.092451, lng: -84.433958},
    {lat: 39.094272, lng: -84.432840},
    {lat: 39.103241, lng: -84.436227},
    {lat: 39.114430, lng: -84.445154},
    {lat: 39.120490, lng: -84.455368},
    {lat: 39.121788, lng: -84.461977},
    {lat: 39.121455, lng: -84.470603},
    {lat: 39.116761, lng: -84.480953},
    {lat: 39.111323, lng: -84.487232},
    {lat: 39.102466, lng: -84.493732},
    {lat: 39.096763, lng: -84.501929},
    {lat: 39.095038, lng: -84.506116},
    {lat: 39.091840, lng: -84.503444},
    {lat: 39.087685, lng: -84.504209},
    {lat: 39.084065, lng: -84.503406},
    {lat: 39.072430, lng: -84.493427},
    {lat: 39.071065, lng: -84.493006},
    {lat: 39.058067, lng: -84.497557},
    {lat: 39.054572, lng: -84.496677},
    {lat: 39.049749, lng: -84.493164},
    {lat: 39.045597, lng: -84.487666},
    {lat: 39.040378, lng: -84.485018},
    {lat: 39.032785, lng: -84.489346},
    {lat: 39.028712, lng: -84.490008},
    {lat: 39.021672, lng: -84.489142},
    {lat: 39.015146, lng: -84.484730},
    {lat: 39.013356, lng: -84.481344},
    {lat: 39.004653, lng: -84.473439},
    {lat: 39.003571, lng: -84.461450},
    {lat: 38.999635, lng: -84.453839},
    {lat: 38.996351, lng: -84.450581},
    {lat: 38.993566, lng: -84.450751},
    {lat: 38.985356, lng: -84.459941},
    {lat: 38.979806, lng: -84.464637},
    {lat: 38.974512, lng: -84.466063},
    {lat: 38.968560, lng: -84.463536},
    {lat: 38.967224, lng: -84.462021},
    {lat: 38.965642, lng: -84.458385},
    {lat: 38.965642, lng: -84.454346},
    {lat: 38.966066, lng: -84.453357},
    {lat: 38.968805, lng: -84.451796},
    {lat: 38.974240, lng: -84.450421},
    {lat: 38.977235, lng: -84.448760},
    {lat: 38.984272, lng: -84.440810},
    {lat: 38.985414, lng: -84.436536},
    {lat: 38.984635, lng: -84.432210},
    {lat: 38.979936, lng: -84.426595},
    {lat: 38.974257, lng: -84.428314},
    {lat: 38.964880, lng: -84.438570},
    {lat: 38.959467, lng: -84.441606},
    {lat: 38.956304, lng: -84.441864},
    {lat: 38.950535, lng: -84.446946},
    {lat: 38.949048, lng: -84.462098},
    {lat: 38.947283, lng: -84.462422},
    {lat: 38.930676, lng: -84.451564},
    {lat: 38.915310, lng: -84.443724},
    {lat: 38.912156, lng: -84.444656},
    {lat: 38.907709, lng: -84.441648},
    {lat: 38.906742, lng: -84.439459},
    {lat: 38.904987, lng: -84.438932},
    {lat: 38.897869, lng: -84.439444},
    {lat: 38.883931, lng: -84.444054},
    {lat: 38.870942, lng: -84.449668},
    {lat: 38.866579, lng: -84.452717},
    {lat: 38.864709, lng: -84.452087},
    {lat: 38.862680, lng: -84.449549},
    {lat: 38.861194, lng: -84.449651},
    {lat: 38.858263, lng: -84.452598},
    {lat: 38.851631, lng: -84.463483},
    {lat: 38.849137, lng: -84.463466},
    {lat: 38.843883, lng: -84.459411},
    {lat: 38.840420, lng: -84.454846},
    {lat: 38.837807, lng: -84.453041},
    {lat: 38.827643, lng: -84.453347},
    {lat: 38.820322, lng: -84.451664},
    {lat: 38.819062, lng: -84.450556},
    {lat: 38.817071, lng: -84.445412},
    {lat: 38.818112, lng: -84.442068},
    {lat: 38.825153, lng: -84.437833},
    {lat: 38.827200, lng: -84.435097},
    {lat: 38.828387, lng: -84.431460},
    {lat: 38.827827, lng: -84.426976},
    {lat: 38.824860, lng: -84.422588},
    {lat: 38.822829, lng: -84.421322},
    {lat: 38.816969, lng: -84.419905},
    {lat: 38.813667, lng: -84.417567},
    {lat: 38.810654, lng: -84.417842},
    {lat: 38.808580, lng: -84.421168},
    {lat: 38.806540, lng: -84.418206},
    {lat: 38.874731, lng: -84.231914},
    {lat: 38.873776, lng: -84.231766},
    {lat: 38.893219, lng: -84.234401},
    {lat: 38.923531, lng: -84.257200},
    {lat: 38.954632, lng: -84.287447},
    {lat: 38.968262, lng: -84.295238},
    {lat: 38.990261, lng: -84.297414},
    {lat: 39.013143, lng: -84.310246},
    {lat: 39.022845, lng: -84.319641},
    {lat: 39.022845, lng: -84.319641},
]
kentuckyCountiesByName.push({state: "Kentucky", name: "Campbell"})

export const KentonCounty = [
    {lat: 38.792811, lng: -84.605009},
    {lat: 38.802275, lng: -84.615662},
    {lat: 38.879424, lng: -84.597930},
    {lat: 39.074342, lng: -84.623503},
    {lat: 39.073307, lng: -84.618372},
    {lat: 39.073461, lng: -84.613784},
    {lat: 39.073468, lng: -84.605852},
    {lat: 39.074521, lng: -84.600792},
    {lat: 39.076415, lng: -84.597344},
    {lat: 39.077604, lng: -84.593682},
    {lat: 39.078485, lng: -84.589688},
    {lat: 39.079575, lng: -84.584776},
    {lat: 39.080585, lng: -84.579213},
    {lat: 39.081080, lng: -84.576572},
    {lat: 39.082190, lng: -84.572592},
    {lat: 39.083418, lng: -84.570129},
    {lat: 39.088757, lng: -84.563138},
    {lat: 39.094319, lng: -84.558439},
    {lat: 39.098283, lng: -84.553868},
    {lat: 39.098965, lng: -84.552538},
    {lat: 39.099598, lng: -84.550531},
    {lat: 39.099964, lng: -84.545521},
    {lat: 39.099540, lng: -84.543772},
    {lat: 39.099556, lng: -84.542292},
    {lat: 39.097136, lng: -84.535654},
    {lat: 39.092735, lng: -84.526612},
    {lat: 39.092241, lng: -84.523505},
    {lat: 39.092474, lng: -84.518956},
    {lat: 39.093848, lng: -84.510437},
    {lat: 39.095038, lng: -84.506116},
    {lat: 39.091840, lng: -84.503444},
    {lat: 39.087685, lng: -84.504209},
    {lat: 39.084065, lng: -84.503406},
    {lat: 39.072430, lng: -84.493427},
    {lat: 39.071065, lng: -84.493006},
    {lat: 39.058067, lng: -84.497557},
    {lat: 39.054572, lng: -84.496677},
    {lat: 39.049749, lng: -84.493164},
    {lat: 39.045597, lng: -84.487666},
    {lat: 39.040378, lng: -84.485018},
    {lat: 39.032785, lng: -84.489346},
    {lat: 39.028712, lng: -84.490008},
    {lat: 39.021672, lng: -84.489142},
    {lat: 39.015146, lng: -84.484730},
    {lat: 39.013356, lng: -84.481344},
    {lat: 39.004653, lng: -84.473439},
    {lat: 39.003571, lng: -84.461450},
    {lat: 38.999635, lng: -84.453839},
    {lat: 38.996351, lng: -84.450581},
    {lat: 38.993566, lng: -84.450751},
    {lat: 38.985356, lng: -84.459941},
    {lat: 38.979806, lng: -84.464637},
    {lat: 38.974512, lng: -84.466063},
    {lat: 38.968560, lng: -84.463536},
    {lat: 38.967224, lng: -84.462021},
    {lat: 38.965642, lng: -84.458385},
    {lat: 38.965642, lng: -84.454346},
    {lat: 38.966066, lng: -84.453357},
    {lat: 38.968805, lng: -84.451796},
    {lat: 38.974240, lng: -84.450421},
    {lat: 38.977235, lng: -84.448760},
    {lat: 38.984272, lng: -84.440810},
    {lat: 38.985414, lng: -84.436536},
    {lat: 38.984635, lng: -84.432210},
    {lat: 38.979936, lng: -84.426595},
    {lat: 38.974257, lng: -84.428314},
    {lat: 38.964880, lng: -84.438570},
    {lat: 38.959467, lng: -84.441606},
    {lat: 38.956304, lng: -84.441864},
    {lat: 38.950535, lng: -84.446946},
    {lat: 38.949048, lng: -84.462098},
    {lat: 38.947283, lng: -84.462422},
    {lat: 38.930676, lng: -84.451564},
    {lat: 38.915310, lng: -84.443724},
    {lat: 38.912156, lng: -84.444656},
    {lat: 38.907709, lng: -84.441648},
    {lat: 38.906742, lng: -84.439459},
    {lat: 38.904987, lng: -84.438932},
    {lat: 38.897869, lng: -84.439444},
    {lat: 38.883931, lng: -84.444054},
    {lat: 38.870942, lng: -84.449668},
    {lat: 38.866579, lng: -84.452717},
    {lat: 38.864709, lng: -84.452087},
    {lat: 38.862680, lng: -84.449549},
    {lat: 38.861194, lng: -84.449651},
    {lat: 38.858263, lng: -84.452598},
    {lat: 38.851631, lng: -84.463483},
    {lat: 38.849137, lng: -84.463466},
    {lat: 38.843883, lng: -84.459411},
    {lat: 38.840420, lng: -84.454846},
    {lat: 38.837807, lng: -84.453041},
    {lat: 38.827643, lng: -84.453347},
    {lat: 38.820322, lng: -84.451664},
    {lat: 38.819062, lng: -84.450556},
    {lat: 38.817071, lng: -84.445412},
    {lat: 38.818112, lng: -84.442068},
    {lat: 38.825153, lng: -84.437833},
    {lat: 38.827200, lng: -84.435097},
    {lat: 38.828387, lng: -84.431460},
    {lat: 38.827827, lng: -84.426976},
    {lat: 38.824860, lng: -84.422588},
    {lat: 38.822829, lng: -84.421322},
    {lat: 38.816969, lng: -84.419905},
    {lat: 38.813667, lng: -84.417567},
    {lat: 38.810654, lng: -84.417842},
    {lat: 38.808580, lng: -84.421168},
    {lat: 38.806540, lng: -84.418206},
    {lat: 38.805880, lng: -84.418507},
    {lat: 38.790117, lng: -84.547283},
    {lat: 38.791150, lng: -84.597684},
    {lat: 38.792823, lng: -84.604893}
]
kentuckyCountiesByName.push({state: "Kentucky", name: "Kenton"})


export const supportedOhioCounties = ohioCountiesByName;
export const supportedKentuckyCounties = kentuckyCountiesByName;