import styled from "styled-components"
import '../styles/pricestyle.css'
import { useEffect, useState } from "react";
import { db } from "../../firebase";

import { motion } from "framer-motion";
import OrderButton from "../Orders"

const PricingContainer = () =>{
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() =>{
        setLoading(true)
        try{
            const dataGrab = db.collection("products")
            .onSnapshot((querySnapshot) =>{
                const tempArray = []
                querySnapshot.forEach((doc) =>{
                    tempArray.push({
                        id: doc.data().id,
                        title: doc.data().name,
                        price: doc.data().price,
                        quantity: doc.data().quantity,
                        status: doc.data().status
                    })
                })
                setProducts(tempArray)
                setLoading(false)
            })
            return () => dataGrab();
        }catch(error){
            console.log(error.message)
        }
    }, [])

    return(
        <PriceContainer initial={{height: 0}} animate={{height: 650}} exit={{height: 0}} transition={{duration: 0.4}}>
            {loading ? 
                <LoadingDiv>
                    <LoadingText>Loading...</LoadingText>
                </LoadingDiv> 
            : 
            <>
                <PriceTable>
                    <thead>
                        <tr id="headerTableRow">
                            <th id="unitHeader">Unit</th>
                            <th id="priceHeader">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product, idx) => (
                        <tr id="tableRow">
                            <td id="unitTableData" key={idx} >{product.title}</td>
                            {product.status ?
                                <td id="priceTableData" key={idx}>${product.price}.00</td>
                                :
                                <td id="priceTableData" key={idx}>Unavailable</td>
                            }
                        </tr>))}
                    </tbody>
                </PriceTable>
                <DisclaimerContainer>
                    <Disclaimerh2>* All Monthly units will be serviced weekly. Charged on a 28 day billing cycle. Local taxes included.</Disclaimerh2>
                </DisclaimerContainer>
            </>
            }
        </PriceContainer>
    );
}

//The actual Price Chart
const PriceContainer = styled(motion.div)`
    border 2px solid white;
    border-radius: 5px;
    width: 25%;
    height 650px;
    margin: auto;
    background: rgba(0,0,0,0.3);
    overflow-y: auto;
    @media(max-width: 900px){
        height 585px;
        width: 75%;
    };
    @media(max-width: 900px){
        height 585px;
        width: 90%;
    };
    &::-webkit-scrollbar {
        background: transparent;
        border: 1px solid white;
    };
    &::-webkit-scrollbar-thumb {
        background-color: #004E95;
        border: 1px solid white;
        border-radius: 5px;
        padding: 5px;
    };
`

const LoadingDiv = styled.div`
    margin: auto;
`

const LoadingText = styled.p`
    text-align: center;
    margin: auto;
    font-size: 30px;
    color: white;
`

const PriceTable = styled(motion.table)`
    margin: 10px;
    padding: 10px;
    text-align: left;
    color: white;
    border-collapse: collapse;
    font-size: 24px;
`

const DisclaimerContainer = styled(motion.div)``

const Disclaimerh2 = styled(motion.h4)`
    margin 0px 10px;
    color: white;
    @media(max-width: 800px){
        font-size: 12px;
    }
`

export default PricingContainer