import { createContext, useState } from "react";
import { getProductData } from "./productStore";

//Initiate the functions/items
export const CartContext = createContext({
    items: [],
    getProductQuantity: () => {},
    addOneToCard: () => {},
    removeOneFromCart: () => {},
    deleteFromCart: () => {},
    getTotalCost: () => {},
    emptyCart: () => {},
    addMultipleItemsToCart: () => {}
});

//Add Logic
export function CartProvider({children}){

    const [cartProducts, setCartProducts] = useState([]);

    function getProductQuantity(id){
        const quantity = cartProducts.find(product => product.id === id)?.quantity
    
        if(quantity === undefined){
            return 0;
        }
        return quantity
    }

    function addOneToCart(id){
        const quantity = getProductQuantity(id);

        //No items added to cart
        if(quantity === 0){
            setCartProducts(
                [
                    ...cartProducts, //Add items to beginning of the array
                    {
                        id: id,
                        quantity: 1
                    }
                ]
            )
        }else{ //There is an item already in cart
            setCartProducts(
                cartProducts.map(
                    product =>
                    product.id === id //If condition
                    ? { ...product, quantity: product.quantity + 1, name: product.name}   //If true
                    : product                                       //If False
                )
            )
        }
    }

    function addMultipleItemsToCart(id, qnty, name){
        const quantity = getProductQuantity(id);

        //No items added to cart
        if(quantity === 0){
            setCartProducts(
                [
                    ...cartProducts, //Add items to beginning of the array
                    {
                        id: id,
                        quantity: parseInt(qnty),
                        name: name
                    }
                ]
            )
        }else{ //There is an item already in cart
            setCartProducts(
                cartProducts.map(
                    product =>
                    product.id === id //If condition
                    ? { ...product, quantity: product.quantity + parseInt(qnty), name: product.name}   //If true
                    : product                                       //If False
                )
            )
        }
    }

    function removeOneFromCart(id){
        const quantity = getProductQuantity(id);
        if(quantity === 1){
            deleteFromCart(id)
        }else{
            setCartProducts(
                cartProducts.map(
                    product =>
                    product.id === id //If condition
                    ? { ...product, quantity: product.quantity - 1, name: product.name}   //If true
                    : product                                       //If False
                )
            )
        }
    }

    function deleteFromCart(id){
        // filter out an item from an array
        setCartProducts(
            cartProducts =>
            cartProducts.filter(currentProduct => {
                return currentProduct.id != id;
            })
        )
    }

    function emptyCart(){
        setCartProducts([]);
    }

    function getTotalCost(){
        let totalCost = 0;
        cartProducts.map((cartItem) =>{
            const productData = getProductData(cartItem.id);
            totalCost += (productData.price * cartItem.quantity)
        })

        return totalCost;
    }
    
    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost,
        emptyCart,
        addMultipleItemsToCart
    }

    return (
    <CartContext.Provider value={contextValue}>
        {children}
    </CartContext.Provider>
    )
}

export default CartProvider;