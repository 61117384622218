import styled from "styled-components"
import { useState, useContext } from "react";

import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from "react-bootstrap/esm/DropdownItem";

import { productArray } from "../../productStore";
import { CartContext } from "../../CartContext";

const OrderModalTable = () =>{

    const cart = useContext(CartContext)
    const [qnty, setQnty] = useState(0)
    const [name, setName] = useState('NAME');
    const [btnTitle, setBtnTitle] = useState("Select a product")
    const [product, setProduct] = useState("Product")

    const addOneQnty = (maxQnty, prodName) =>{
        if(qnty < maxQnty){
            setQnty(qnty+1)
            setName(prodName)
        }else{
            alert("There are only " + maxQnty + " in stock. \n Please give us a call and we will be happy to work with you. We return calls within the same day")
        }
    }
    
    const removeOneQnty = () =>{
        if(qnty > 0){
            setQnty(qnty-1)
            console.log("Removed 1 to Quantity: " + qnty)
        }else{
            alert("Cannot remove any more")
        }
    }

    return(
        <>
        <PriceTable>
            <thead>
                <tr id="trOrderHeaderRow">
                    <th id="thOrderHeaderUnit">Unit</th>
                    <th id="thOrderHeaderQuantity">Quantity</th>
                </tr>
            </thead>
            <tbody id="table">
            <tr>
                <td id="tdOrderTitle">  
                    <DropButton id="dropButton" title={btnTitle}>
                        {productArray.map((item) =>(
                            <DropItem id="dropItem" onClick={() => {setProduct(item); setQnty(0); setBtnTitle(item.title)}}>{item.title}</DropItem>
                        ))}
                    </DropButton>
                </td>
                <td id="tdOrderQuantity">
                    <ChangeQuantityContainer>
                        <RemoveOneButton onClick={() => removeOneQnty(product.quantity)}>-</RemoveOneButton>
                            <DisplayQuantity>{qnty}</DisplayQuantity>
                        <AddOneButton onClick={() => addOneQnty(product.quantity, product.title)}>+</AddOneButton>
                    </ChangeQuantityContainer>
                </td>
            </tr>
            </tbody>
        </PriceTable>
        <AddToCart onClick={() => {
            if(qnty > 0){
                cart.addMultipleItemsToCart(product.id, qnty, name)
                }else{alert("Please enter a quantity")}
            }}>Add To Cart</AddToCart>
        </>
    )
}

const PriceTable = styled.table`
    border-collapse: collapse;
    border: 1px solid white;
    overflow: fixed;
    @media(max-width: 800px){
        margin-left: -10px;
    }
`

const DropButton = styled(DropdownButton)`

`

const DropItem = styled(DropdownItem)`

`

const ChangeQuantityContainer = styled.div`
    display: flex;
    margin-top: 10px;
    @media(max-width: 800px){
        font-size: 18px;
    }
`

const RemoveOneButton = styled.button`
    width: 30px;
    height: 30px;
    line-height: 0px;
    border: 0px;
    margin-left: 10px;
    margin-right: 10px;
    background: none;
    color: white;
    font-size: 32px;
    @media(max-width: 800px){
        font-size: 18px;
        margin: 0px;
    }
`

const AddOneButton = styled.button`
    width: 30px;
    height: 30px;
    line-height: 0px;
    border: 0px;
    margin-left: 10px;
    margin-right: 10px;
    background: none;
    color: white;
    font-size: 32px;
    @media(max-width: 800px){
        font-size: 18px;
        margin: 0px;
    }
`

const DisplayQuantity = styled.p`

`

const AddToCart = styled.button`
    width: 150px;
    height: 50px;
    margin: 0px;
    padding: 0px;
    background: transparent;
    color: white;
    border: none;
    width: 100%;
    border: 1px solid white;
    margin-top: 10px;
    &:hover{
        color: #99ceff;
    };
`

export default OrderModalTable;