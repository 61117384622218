//Regular Unit: price_1Mu6UtCoKpbiFbChenmtZ0td
//Regular Event: price_1Mu6TjCoKpbiFbChmx0hSSPU
//Handicap Event: price_1Mu6USCoKpbiFbChGGVSb2Gx
//ADA Event: price_1Mu6VdCoKpbiFbChZvyKfv2S
//Additional Service: price_1Mu6vDCoKpbiFbChNILOvbHd
import { useEffect, useState } from "react";
import { db } from "./firebase";

const productArray = []

const ProductStore = () =>{
    useEffect(() =>{
        const dataGrab = db.collection("products")
        .onSnapshot((querySnapshot) =>{
            const tempArray = []
            querySnapshot.forEach((doc) =>{
                tempArray.push({
                    id: doc.data().id,
                    title: doc.data().name,
                    price: doc.data().price,
                    quantity: doc.data().quantity
                })
            })
            tempArray.forEach((doc) =>{
                productArray.push(doc)
            })
        })
        return () => dataGrab();
    }, [])
}

const getProductData = (id) =>{
    let productData = productArray.find(product => product.id === id)
    if(productData === undefined){
        console.log("Product Data not found with ID: " + id)
    }
    return productData
}

export { productArray, getProductData };
export default ProductStore